.contact-container {
  .background-image-outer {
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 201, 1) 0%,
      rgba(252, 252, 254, 1) 0%,
      rgba(243, 241, 255, 1) 18%,
      rgba(249, 251, 255, 1) 47%,
      rgba(226, 242, 255, 1) 72%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 384px;

    .background-image {
      width: 100%;
    }

    .background-text {
      height: 100%;
      display: flex;
      align-items: center;
      align-items: center;
      text-align: left;
      justify-content: center;

      .background-text-inner {
        margin: auto;

        .heading {
          color: #333333;
          font-weight: 800;
          font-size: 74px;
          text-align: center;
        }

        .sub-heading {
          font-size: 22px;
          color: #666666;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }

  .contact-container-inner {
    display: flex;
    background-color: white;
    width: 85%;
    margin: auto;
    margin-top: -58px;
    position: relative;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 80px;
    height: auto;

    .contact-info-main {
      background-color: #32a0ef;
      padding: 20px;
      border-radius: 8px;
      width: 50%;
      text-align: left;

      .contact-info {
        width: 70%;
        margin: auto;
        margin-top: 30px;

        .contact-info-heading {
          color: white;
          font-weight: 600;
          font-size: 38px;
        }

        .contact-info-sub-heading {
          margin-bottom: 10px;
          margin-top: 10px;
          color: rgba(255, 255, 255, 0.8);
          font-size: 20px;
        }

        .contact-info-phone {
          color: white;
          font-weight: 400;
          display: flex;
          margin-top: 40px;
          gap: 15px;
          align-items: center;
          .phone-number {
            display: flex;
            flex-direction: column;
            line-height: 25px;
          }
        }

        .contact-info-email {
          color: white;
          font-weight: 400;
          display: flex;
          gap: 15px;
          align-items: center;
          margin-top: 15px;
        }

        .contact-info-address {
          color: white;
          font-weight: 400;
          margin-top: 15px;
          display: flex;
          gap: 15px;
          align-items: center;
        }

        .social-media {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          margin-top: 100px;

          img {
            width: 25px;
          }

          a {
            font-size: 24px;
            color: #4b90fe;

            &:hover {
              color: darken(#4b90fe, 10%);
            }
          }
        }
      }
    }

    .contact-form {
      width: 50%;
      background-color: white;
      padding: 20px;

      .contact-form-heading-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-heading {
          color: rgba(50, 160, 239, 1);
          font-weight: 600;
          font-size: 25px;
        }

        .message-icon {
          width: 50px;
        }
      }

      h2 {
        color: #4b90fe;
      }

      form {
        width: 100%;
        margin-top: 20px;

        .main-form-group {
          display: flex;
          gap: 75px;
          text-align: left;
          display: flex;

          .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            width: 40%;

            input,
            textarea {
              width: 100%;
              outline: none;
              padding: 10px;
              border-radius: 0px;
              border: none;
              border-bottom: 1.5px solid rgba(204, 204, 204, 0.6);

              &:focus {
                border-color: #4b90fe;
              }
            }

            textarea {
              resize: none;
              height: 100px;
              width: 465px;
            }
          }
        }

        .form-group {
          flex-direction: column;
          display: flex;
          gap: 10px;
          margin-bottom: 15px;
          text-align: left;

          label {
            color: rgb(102, 102, 102);
            font-size: 14px;
            font-weight: 500;
          }

          input,
          textarea {
            width: 100%;
            outline: none;
            padding: 10px;
            border-radius: 0px;
            border: none;
            border-bottom: 1.5px solid rgba(204, 204, 204, 0.6);

            &:focus {
              border-color: #4b90fe;
            }
          }

          textarea {
            resize: none;
            height: 100px;
          }

          ::placeholder {
            color: rgba(204, 204, 204, 1);
            font-size: 12px;
          }
        }

        .error-field {
          input,
          select,
          textarea {
            border-bottom: 1.5px solid #d9534f !important;

            &:focus {
              border-color: #d1332e !important;
            }
          }
        }

        .main-form-group {
          .checkbox-group {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
            flex-direction: row;

            input {
              margin-right: 10px;
              width: 10px;
            }

            label {
              a {
                color: #4b90fe;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .submit-btn {
          background-color: #4b90fe;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken(#4b90fe, 10%);
          }
        }

        .submit-btn.disabled {
          background-color: gray;
          cursor: not-allowed;
          opacity: 0.7;
        }

        .phone-input-wrapper {
          display: flex;
          align-items: center;
          width: 215px;

          select {
            padding: 10px;
            border: none;
            border-bottom: 1.5px solid rgba(204, 204, 204, 0.6);
            border-radius: 0px;
            background-color: white;
            appearance: none;
            font-size: 14px;
            cursor: pointer;
            color: rgba(102, 102, 102, 1);

            &:focus {
              outline: none;
              border-color: #4b90fe;
            }
          }

          .line-bet {
            height: 15px;
            background-color: rgba(204, 204, 204, 1);
            width: 1.5px;
          }

          input {
            width: 100%;
            padding: 10px;
            border: 1px solid rgba(204, 204, 204, 0.6);
            border-left: none; // To merge with the select dropdown
            border-radius: 0 4px 4px 0;

            &:focus {
              border-color: #4b90fe;
            }
          }
        }
      }
    }
  }

  .error {
    color: #ff4d4d;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0;
    font-weight: 500;
  }
}

.error {
  color: #d9534f;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.contact-btn {
  margin-top: 5px;
}

@media (max-width: 319.98px) {
  .contact-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}

// Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .contact-container {
    width: 100%;

    .background-image-outer {
      .background-image {
      }

      .background-text {
        .background-text-inner {
          text-align: center;

          .heading {
            font-size: 50px;
            color: #333333;
            font-weight: 800;
            text-align: center;
          }

          .sub-heading {
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
    }

    .contact-container-inner {
      margin-top: 10px;
      flex-direction: column;
      gap: 30px;

      .contact-info-main {
        padding: 0px;
        width: 100%;

        .contact-info {
          width: 85%;

          .contact-info-heading {
            font-size: 19px;
            text-align: center;
          }

          .contact-info-sub-heading {
            font-size: 15px;
            text-align: center;
          }

          .contact-info-phone {
            margin-top: 30px;
          }

          .contact-info-email {
            .email-img {
            }

            .text-email {
            }
          }

          .contact-info-address {
            color: white;
            font-weight: 400;
            margin-top: 15px;
            display: flex;
            gap: 15px;
            align-items: center;

            .location-img {
            }

            .text-address {
            }
          }

          .social-media {
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 10px;
          }
        }
      }

      .contact-form {
        width: 100%;
        padding: 0px;

        .contact-form-heading-icon {
          .form-heading {
            font-size: 20px;
          }

          .message-icon {
            width: 45px;
          }
        }

        h2 {
        }

        form {
          .main-form-group {
            gap: 5px;
            flex-direction: column;

            .form-group {
              width: 90%;
              margin: auto;

              input,
              textarea {
                padding: 0px;
              }

              textarea {
                resize: none;
                height: 50px;
                max-width: 443px;
                width: 100%;
              }
            }
          }

          .form-group {
            label {
              font-size: 15px;
              margin-top: 5px;
            }

            input,
            textarea {
            }

            textarea {
              resize: none;
              height: 100px;
            }
          }

          .main-form-group {
            .checkbox-group {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              flex-direction: row;

              input {
                margin-right: 10px;
                width: 10px;
              }

              label {
                a {
                  color: #4b90fe;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .submit-btn {
            padding: 5px 15px;
            font-size: 13px;
          }

          .phone-input-wrapper {
            display: flex;
            align-items: center;
            max-width: 443px;
            width: 100%;

            select {
              padding: 0px;
              font-size: 10px;
            }

            .line-bet {
              height: 15px;
              background-color: rgba(204, 204, 204, 1);
              width: 1.5px;
            }

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid rgba(204, 204, 204, 0.6);
              border-left: none; // To merge with the select dropdown
              border-radius: 0 4px 4px 0;

              &:focus {
                border-color: #4b90fe;
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-container {
    .background-image-outer {
      .background-image {
      }

      .background-text {
        display: flex;
        justify-content: center;

        .background-text-inner {
          text-align: center;

          .heading {
            font-size: 50px;
            color: #333333;
            font-weight: 800;
            text-align: center;
          }

          .sub-heading {
            font-size: 18px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
    }

    .contact-container-inner {
      margin-top: 10px;
      flex-direction: column;
      gap: 30px;

      .contact-info-main {
        padding: 0px;
        width: 100%;

        .contact-info {
          width: 85%;

          .contact-info-heading {
            font-size: 19px;
            text-align: center;
          }

          .contact-info-sub-heading {
            font-size: 15px;
            text-align: center;
          }

          .contact-info-phone {
            margin-top: 30px;

            .phone-img {
            }

            .phone-number {
            }
          }

          .contact-info-email {
            .email-img {
            }

            .text-email {
            }
          }

          .contact-info-address {
            color: white;
            font-weight: 400;
            margin-top: 15px;
            display: flex;
            gap: 15px;
            align-items: center;

            .location-img {
            }

            .text-address {
            }
          }

          .social-media {
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 10px;
          }
        }
      }

      .contact-form {
        width: 100%;
        padding: 0px;

        .contact-form-heading-icon {
          .form-heading {
            font-size: 20px;
          }

          .message-icon {
            width: 45px;
          }
        }

        h2 {
        }

        form {
          .main-form-group {
            gap: 5px;
            flex-direction: column;

            .form-group {
              width: 90%;
              margin: auto;

              input,
              textarea {
                padding: 0px;
                max-width: 579px;
                width: 100%;
                padding: 0px;
              }

              textarea {
                resize: none;
                height: 50px;
              }
            }
          }

          .form-group {
            label {
              margin-top: 5px;
            }

            input,
            textarea {
            }

            textarea {
              resize: none;
              height: 100px;
            }
          }

          .main-form-group {
            .checkbox-group {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              flex-direction: row;

              input {
                margin-right: 10px;
                width: 10px;
              }

              label {
                a {
                  color: #4b90fe;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .submit-btn {
            padding: 5px 15px;
            font-size: 13px;
          }

          .phone-input-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 557px;

            select {
              padding: 0px;
            }

            .line-bet {
              height: 15px;
              background-color: rgba(204, 204, 204, 1);
              width: 1.5px;
            }

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid rgba(204, 204, 204, 0.6);
              border-left: none; // To merge with the select dropdown
              border-radius: 0 4px 4px 0;

              &:focus {
                border-color: #4b90fe;
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-container {
    .background-image-outer {
      .background-image {
      }

      .background-text {
        display: flex;
        justify-content: center;

        .background-text-inner {
          text-align: center;

          .heading {
            font-size: 60px;
            text-align: center;
          }

          .sub-heading {
            font-size: 17px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
    }

    .contact-container-inner {
      margin-top: 10px;
      flex-direction: column;
      gap: 30px;

      .contact-info-main {
        padding: 0px;
        width: 100%;

        .contact-info {
          width: 85%;

          .contact-info-heading {
            font-size: 25px;
            text-align: center;
          }

          .contact-info-sub-heading {
            font-size: 19px;
            text-align: center;
          }

          .contact-info-phone {
            margin-top: 30px;

            .phone-img {
            }

            .phone-number {
              flex-direction: row;
              gap: 20px;
            }
          }

          .contact-info-email {
            .email-img {
            }

            .text-email {
            }
          }

          .contact-info-address {
            color: white;
            font-weight: 400;
            margin-top: 15px;
            display: flex;
            gap: 15px;
            align-items: center;

            .location-img {
            }

            .text-address {
            }
          }

          .social-media {
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 10px;
          }
        }
      }

      .contact-form {
        width: 100%;
        padding: 0px;

        .contact-form-heading-icon {
          .form-heading {
            font-size: 20px;
          }

          .message-icon {
            width: 45px;
          }
        }

        h2 {
        }

        form {
          .main-form-group {
            gap: 5px;
            flex-direction: column;

            .form-group {
              width: 90%;
              margin: auto;

              input,
              textarea {
                padding: 0px;
              }

              textarea {
                resize: none;
                height: 50px;
                width: 100%;
                max-width: 758px;
              }
            }
          }

          .form-group {
            label {
              margin-top: 5px;
              font-size: 18px;
            }

            input,
            textarea {
            }

            textarea {
              resize: none;
              height: 100px;
            }
          }

          .main-form-group {
            .checkbox-group {
              display: flex;
              margin-top: 10px;
              align-items: center;
              margin-bottom: 20px;
              flex-direction: row;

              input {
                margin-right: 10px;
                width: 10px;
              }

              label {
                a {
                  color: #4b90fe;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .submit-btn {
            padding: 10px 20px;
            font-size: 15px;
          }

          .phone-input-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 757px;

            select {
              padding: 0px;
            }

            .line-bet {
              height: 15px;
              background-color: rgba(204, 204, 204, 1);
              width: 1.5px;
            }

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid rgba(204, 204, 204, 0.6);
              border-left: none; // To merge with the select dropdown
              border-radius: 0 4px 4px 0;

              &:focus {
                border-color: #4b90fe;
              }
            }
          }
        }
      }
    }
  }
}

// Extra large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact-container {
    .background-image-outer {
      .background-image {
      }

      .background-text {
        display: flex;
        justify-content: center;

        .background-text-inner {
          text-align: center;

          .heading {
            font-size: 60px;
            text-align: center;
            color: #333333;
            font-weight: 800;
          }

          .sub-heading {
            font-size: 18px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
    }

    .contact-container-inner {
      margin-top: 10px;
      flex-direction: column;
      gap: 30px;

      .contact-info-main {
        padding: 0px;
        width: 100%;

        .contact-info {
          width: 85%;

          .contact-info-heading {
            font-size: 25px;
            text-align: center;
          }

          .contact-info-sub-heading {
            font-size: 19px;
            text-align: center;
          }

          .contact-info-phone {
            margin-top: 30px;

            .phone-img {
            }

            .phone-number {
              flex-direction: row;
              gap: 20px;
            }
          }

          .contact-info-email {
            .email-img {
            }

            .text-email {
            }
          }

          .contact-info-address {
            color: white;
            font-weight: 400;
            margin-top: 15px;
            display: flex;
            gap: 15px;
            align-items: center;

            .location-img {
            }

            .text-address {
            }
          }

          .social-media {
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 10px;
          }
        }
      }

      .contact-form {
        width: 100%;
        padding: 0px;

        .contact-form-heading-icon {
          .form-heading {
            font-size: 20px;
          }

          .message-icon {
            width: 45px;
          }
        }

        h2 {
        }

        form {
          .main-form-group {
            gap: 5px;
            flex-direction: column;

            .form-group {
              width: 90%;
              margin: auto;

              input,
              textarea {
                padding: 0px;
              }

              textarea {
                resize: none;
                height: 50px;
                width: 100%;
                max-width: 758px;
              }
            }
          }

          .form-group {
            label {
              margin-top: 5px;
              font-size: 18px;
            }

            input,
            textarea {
            }

            textarea {
              resize: none;
              height: 100px;
            }
          }

          .main-form-group {
            .checkbox-group {
              display: flex;
              margin-top: 10px;
              align-items: center;
              margin-bottom: 20px;
              flex-direction: row;

              input {
                margin-right: 10px;
                width: 10px;
              }

              label {
                a {
                  color: #4b90fe;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .submit-btn {
            padding: 10px 20px;
            font-size: 15px;
          }

          .phone-input-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 757px;

            select {
              padding: 0px;
            }

            .line-bet {
              height: 15px;
              background-color: rgba(204, 204, 204, 1);
              width: 1.5px;
            }

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid rgba(204, 204, 204, 0.6);
              border-left: none; // To merge with the select dropdown
              border-radius: 0 4px 4px 0;

              &:focus {
                border-color: #4b90fe;
              }
            }
          }
        }
      }
    }
  }
}

// Extra large screens (1200px and up)
@media (min-width: 1200px) and (max-width: 1600px) {
}
