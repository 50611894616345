.join-link-organisation {
  background-color: #ffffff;
  margin: 120px auto;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: min-content;
  .header {
    display: flex;
    flex-direction: column;
    .logo {
      width: 100px;
    }
  }
  .lock-icon {
    width: 100%;
    height: 100px;
  }
  .container {
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    .main-heading {
      color: black;
      font-size: 24px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    h2 {
      margin-bottom: 20px;
      color: rgba(0, 171, 227, 1);
      font-size: 22px;
      font-weight: 700;
      border-radius: 5px;
      padding: 10px;
    }
    .form-corporate-newUsername {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .input-wrapper {
        position: relative;
        max-width: 300px;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 20px;
        outline: none;
        .username-input {
          font-size: 14px;
          outline: none;
          border: none;
          color: black;
          font-weight: 600;
          width: 90%;
        }
      }
      button {
        display: flex;
        justify-content: center;
        padding: 8px 15px;
        border-radius: 46px;
        background-color: #ffffff;
        border: 1px solid rgba(0, 171, 227, 1);
        color: rgba(0, 171, 227, 1);
        font-size: 16px;
        width: 115px;
        margin: auto;
        text-decoration: none;
        outline: none;
      }
      button:disabled {
        font-weight: 700;
        color: #fff;
        background-color: rgb(129, 128, 128);
        border: none;
        cursor: not-allowed;
      }
    }
    button {
      display: flex;
      justify-content: center;
      padding: 8px 15px;
      border-radius: 46px;
      background-color: #ffffff;
      border: 1px solid rgba(0, 171, 227, 1);
      color: rgba(0, 171, 227, 1);
      font-size: 16px;
      width: 115px;
      margin: auto;
      text-decoration: none;
    }
    button:disabled {
    }
    a {
      text-decoration: none;
    }
  }
}

.input-wrapper.error {
  border-color: red;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.2);

.card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: $box-shadow;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: $box-shadow-hover;
  }

  .store-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; // Allows items to wrap to the next line if needed
    margin: 15px 0;
    padding: 2px 5px;
    a {
      text-decoration: none; // Removes underline
      color: inherit; // Ensures the text color matches its parent
    }
    a:hover {
      text-decoration: none; // Keeps underline removed even on hover
    }
    .store-button-link{
      margin:5px;
    }
    .store-button {
      background-color: black;
      color: white;
      padding: 12px;
      border-radius: 40px;
      margin: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center; // Align content vertically
      justify-content: center; // Center content horizontally
      border: 1px solid black;
      width: 100%;
      &:hover {
        background-color: #000000;
        transform: scale(1.1);
      }
    }

    .store-button-icon {
      display: flex;
      justify-content: start;
    }

    .store-button-title {
      display: grid;
      margin-left: 8px;
    }

    .store-button-text {
      font-size: 12px;
    }

    .store-button-name {
      font-size: 18px;
    }
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Responsive styles */

@media screen and (min-width: 320px) and (max-width: 360px) {
  .main-update-username {
    width: 80%;
    padding: 10px;
    margin-top: 70px;
    .header {
      .logo {
        width: 80px;
      }
    }
  }
  .join-link-organisation {
    padding: 2px;
    width: 100%;
    margin: 0px auto;
    .profile-manager {
      .create-profile {
        width: 100%;
      }
    }
  }

  .heading {
    font-size: 20px;
  }

  .container h2 {
    font-size: 18px;
  }

  .email-input,
  .input-wrapper {
    max-width: 100%;
    padding: 10px;
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .main-update-username {
    width: 75%;
    margin-top: 70px;
    padding: 12px;
    .heading {
      font-size: 22px;
    }
    .container {
      h2 {
        font-size: 24px;
      }
      .input-wrapper {
        max-width: 100%;
        padding: 15px;
      }
    }
  }
  .join-link-organisation {
    padding: 2px;
    margin: 0px auto;
    width: 100%;
    .profile-manager {
      .create-profile {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 575.98px) {
  .main-update-username {
    width: 70%;
    margin-top: 70px;
    .container {
      h2 {
        font-size: 26px;
      }
    }
  }
  .join-link-organisation {
    padding: 2px;
    margin: 0px auto;
    width: 100%;
    .profile-manager {
      .create-profile {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 771.2px) {
  .main-update-username {
    width: 70%;
    .container {
      h2 {
        font-size: 28px;
      }
    }
  }
}

@media screen and (min-width: 772px) and (max-width: 1013px) {
  .main-update-username {
    width: 70%;
    .container {
      h2 {
        font-size: 30px;
      }
    }
  }
}

@media screen and (min-width: 1014px) and (max-width: 1200px) {
  .main-update-username {
    width: 55%;
    .container {
      h2 {
        font-size: 32px;
      }
    }
  }
}
