.about-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 100px;
    @media (max-width: 768px) {
        padding: 50px 10px;
      }
    // background-color: #f4f4f9;
  
    &__content {
        text-align: left;
      max-width: 50%;
      .about-sub-heading {
        background: #E6F5FF;
        color:#32A0EF;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        // margin: auto;
        font-size: 16px;
    }
      
      h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding: 0px 0px 1rem 0px;
        color: #333;
        border-bottom: 2px solid #32A0EF;
      }
  
      p {
        font-size: 1.1rem;
        line-height: 1.6;
        margin-bottom: 1.5rem;
        color: #555;
      }
  
      .about-us__list {
        list-style-type: disc;
        background: #EEF8FF;
        color: grey;
        padding: 1rem;
        border-left: 2px solid #32A0EF;
        li {
          font-size: 1rem;
          margin-bottom: 0.5rem;
          color: #444;
        }
      }
    }
  
    &__image {
      max-width: 47%;
      img {
        width: 100%;
        
      }
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
  
      &__content {
        max-width: 100%;
      }
  
      &__image {
        max-width: 80%;
        margin-top: 2rem;
      }
    }
  
    @media (max-width: 480px) {
      padding: 2rem 1rem;
  
      &__image {
        max-width: 100%;
      }
    }
  }
  