.carousel-wrapper {
    .carousel.carousel-slider {
        display: flex;
        flex-direction: column-reverse;
    }

    .control-dots {
        position: relative;

        // background: #98CFF6;
        .dot {
            border: 2px solid #98CFF6;
            border-radius: 15px;
            box-shadow: none;
            width: 14px;
            transition: all 0.3s;
            opacity: 1;
        }

        .dot.selected {
            width: 50px;
            background: #98CFF6;
        }
    }

    .carousel-tab {
        // height: 600px;
        width: 100%;
        display: flex;
    }

    .tab1 {
        position: relative;

        .tab1-top {
            position: absolute;
            top: 5vw;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            z-index: 1;

            .tab1-top-inner {
                // text-align: left;

                .line1 {
                    background: transparent;
                    border-radius: 50px;
                    width: max-content;
                    padding: 0.6vw 1vw;
                    font-size: 1.2vw;
                    color: #666666;
                    border: 1px solid #32A0EF;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    .chield-duotone-outer {
                        width: 24px;
                        margin: auto;
                        display: flex;
                    }
                }

                .line2 {
                    color: #333333;
                    font-size: 4.2vw;
                    font-weight: bold;
                    margin: 2px auto;

                    .line2-a {
                        span {
                            // color: #32A0EF;
                        }

                    }

                    .line2-b {
                        span {
                            // color: #32A0EF;
                        }
                    }
                }

                .line3 {
                    color: #333333;
                    font-size: 16px;                
                }
            }
        }

        .tab1-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    .tab2 {
        position: relative;

        .tab2-top {
            position: absolute;
            top: 5vw;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;

            .tab2-top-inner {
                // text-align: left;

                .line1 {
                    background: transparent;
                    border-radius: 50px;
                    width: max-content;
                    padding: 0.6vw 1vw;
                    font-size: 1.2vw;
                    color: #666666;
                    border: 1px solid #32A0EF;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    .chield-duotone-outer {
                        width: 24px;
                        margin: auto;
                        display: flex;
                    }
                }

                .line2 {
                    color: #333333;
                    font-size: 4.2vw;
                    font-weight: bold;
                    margin: 10px auto;

                    .line2-a {
                        span {
                            // color: #32A0EF;
                        }

                    }

                    .line2-b {
                        span {
                            // color: #32A0EF;
                        }
                    }
                }

                .line3 {
                    color: #333333;
                    font-size: 20px;                
                }
            }
        }

        .tab2-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    .tab3 {
        position: relative;

        .tab3-top {
            position: absolute;
            top: 5vw;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;

            .tab3-top-inner {
                // text-align: left;

                .line1 {
                    background: transparent;
                    border-radius: 50px;
                    width: max-content;
                    padding: 0.6vw 1vw;
                    font-size: 1.2vw;
                    color: #666666;
                    border: 1px solid #32A0EF;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    .chield-duotone-outer {
                        width: 24px;
                        margin: auto;
                        display: flex;
                    }
                }

                .line2 {
                    color: #333333;
                    font-size: 4.2vw;
                    font-weight: bold;
                    margin: 10px auto;

                    .line2-a {
                        span {
                            // color: #32A0EF;
                        }

                    }

                    .line2-b {
                        span {
                            // color: #32A0EF;
                        }
                    }
                }

                .line3 {
                    color: #333333;
                    font-size: 20px;                
                }
            }
        }

        .tab3-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    .tab4 {
        position: relative;

        .tab4-top {
            position: absolute;
            top: 5vw;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;

            .tab4-top-inner {
                // text-align: left;

                .line1 {
                    background: transparent;
                    border-radius: 50px;
                    width: max-content;
                    padding: 0.6vw 1vw;
                    font-size: 1.2vw;
                    color: #666666;
                    border: 1px solid #32A0EF;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    .chield-duotone-outer {
                        width: 24px;
                        margin: auto;
                        display: flex;
                    }
                }

                .line2 {
                    color: #333333;
                    font-size: 4.2vw;
                    font-weight: bold;
                    margin: 10px auto;

                    .line2-a {
                        span {
                            // color: #32A0EF;
                        }

                    }

                    .line2-b {
                        span {
                            // color: #32A0EF;
                        }
                    }
                }

                .line3 {
                    color: #333333;
                    font-size: 20px;
                }
            }
        }

        .tab4-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }
    .tab5 {
        position: relative;

        .tab5-top {
            position: absolute;
            top: 5vw;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;

            .tab5-top-inner {
                // text-align: left;

                .line1 {
                    background: transparent;
                    border-radius: 50px;
                    width: max-content;
                    padding: 0.6vw 1vw;
                    font-size: 1.2vw;
                    color: #666666;
                    border: 1px solid #32A0EF;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    .chield-duotone-outer {
                        width: 24px;
                        margin: auto;
                        display: flex;
                    }
                }

                .line2 {
                    color: #333333;
                    font-size: 4.2vw;
                    font-weight: bold;
                    margin: 10px auto;

                    .line2-a {
                        span {
                            // color: #32A0EF;
                        }

                    }

                    .line2-b {
                        span {
                            // color: #32A0EF;
                        }
                    }
                }

                .line3 {
                    color: #333333;
                    font-size: 20px;                
                }
            }
        }

        .tab5-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .carousel-wrapper .tab1 .tab1-top .tab1-top-inner .line3 {
        color: #333333;
        font-size: 10px;
    }

    .tab2-top-inner{
        z-index: 1;
    }
    .carousel-wrapper .tab2 .tab2-top .tab2-top-inner .line3 {
        color: #333333;
        font-size: 16px;
    }

    .tab3-top-inner{
        line-height: 15px;
    }
    .carousel-wrapper .tab3 .tab3-top .tab3-top-inner .line3 {
        color: #333333;
        font-size: 16px;
    }

    .tab4-top-inner{
        line-height: 15px;
    }
    .carousel-wrapper .tab4 .tab4-top .tab4-top-inner .line3 {
        color: #333333;
        font-size: 16px;
    }

    .tab5-top-inner{
        line-height: 15px;
    }
    .carousel-wrapper .tab5 .tab5-top .tab5-top-inner .line3 {
        color: #333333;
        font-size: 16px;
    }
  }