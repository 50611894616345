.why-choose-us {
    padding: 50px 100px;
    text-align: left;
    display: flex;
    @media (max-width: 768px) {
        display: block;
        padding: 50px 10px;
    }
    .why-choose-us-sub-heading {
        background: #E6F5FF;
        color: #32A0EF;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 16px;
        // margin: auto;
    }
  
    &__heading {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      color: #333;
    }
  
    &__content {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
  
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  
    &__column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .reason-item:not(:last-child) {
        border-bottom: 1px solid rgb(202 233 255);
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }
  
    .reason-item {
      display: flex;
      align-items: center;
    //   background-color: #f9f9f9;
      padding: 1.5rem;
      margin: 10px;
    //   border-radius: 10px;
    //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;
  
      &:hover {
        transform: translateY(-10px);
      }
  
      &__icon {
        font-size: 2.5rem;
        color: #007bff;
        margin-right: 1.5rem;
      }
  
      &__content {
        text-align: left;
  
        h3 {
          font-size: 1.2rem;
          margin-bottom: 0rem;
          color: #333;
        }
  
        p {
        margin-top: 0px;
          font-size: 1rem;
          color: #666666;
          line-height: 1.6;
        }
      }
    }
  }
  