.home {
  font-family: sans-serif;

  .section-1 {
    width: 100%;

    .background-container {
      display: flex;
      height: 600px;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: -1;

      .left-bg {
        width: 50%;
        text-align: left;

        img {
          height: 100%;
        }
      }

      .right-bg {
        width: 50%;
        text-align: right;

        img {
          height: 100%;
        }
      }
    }

    .carousel-wrapper {
    }
  }

  .trusted-partners-container {
    text-align: center;
    padding: 0px;
    overflow: hidden;
    width: 100vw;

    .trusted-by-heading {
      color: #666666;
    }

    h2 {
      .trusted {
        color: #007bff; // Adjust color
      }

      .digital-security {
        color: #4caf50; // Adjust color
      }
    }

    .partners-logos-wrapper {
      position: relative;
      margin: 0px;
    }

    .partners-logos-wrapper::before,
    .partners-logos-wrapper::after {
      content: "";
      position: absolute;
      top: 0;
      width: 400px;
      /* Width of the fade effect */
      height: 100%;
      pointer-events: none;
      /* Allow clicks to pass through */
      z-index: 1111;
    }

    .partners-logos-wrapper::before {
      left: 0;
      background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
    }

    .partners-logos-wrapper::after {
      right: 0;
      background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }

    .marquee {
      animation: marquee 15s linear infinite; // Adjust speed as needed
    }

    .partners-logos {
      display: flex;
      gap: 60px; // Adjust spacing between logos
      width: max-content;
      margin: 36px;

      .partner-logo {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        filter: grayscale(1);

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    @keyframes marquee {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }

  .cybersecurity-partner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;

    .image-section {
      display: flex;
      gap: 20px;
      width: 45%;
      z-index: 11;

      .image-box {
        width: 66%;
        background: linear-gradient(
          180deg,
          rgba(190, 182, 255, 0.2385547969187676) 0%,
          rgba(140, 200, 255, 0.2237088585434174) 44%
        );
        border-radius: 30px;

        img {
          width: 100%;
        }
      }
    }

    .text-section {
      padding: 0px;
      border-bottom-left-radius: 30px;
      width: 55%;
      padding: 130px 50px;
      display: flex;
      align-items: center;

      .text-section-inner {
        width: 70%;
        margin: auto;
        text-align: left;

        .about-us-btn {
          display: inline-block;
          padding: 5px 15px;
          background-color: #e6f5ff;
          border: none;
          border-radius: 20px;
          font-size: 18px;
          color: #32a0ef;
          margin-bottom: 15px;
        }

        h2 {
          font-size: 28px;
          color: #333;
          margin-bottom: 20px;
        }

        p {
          font-size: 19px;
          color: #555;
          line-height: 1.6;
        }
      }
    }
  }

  .section-4-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    background: linear-gradient(180deg, #ffffff 0%, #f1f9ff 100%);

    .text-section {
      padding: 0px;
      width: 45%;
      padding: 40px 50px;
      display: flex;
      align-items: center;

      .text-section-inner {
        margin: auto;
        text-align: left;

        .heading-testimonial-home {
          background-color: rgb(230, 245, 255);
          width: 20%;
          margin: auto;
          text-align: center;
          border-radius: 20px;
          padding: 5px 15px;
          white-space: nowrap;

          span {
            color: rgba(50, 160, 239, 1);
            font-weight: normal;
            font-size: 18px;
          }
        }

        .heading-testimonial-home-sub {
          margin-top: 15px;
          font-size: 44px;
          font-weight: 700;
          color: rgb(51, 51, 51);
          width: 100%;
          text-align: center;
        }

        p {
          font-size: 19px;
          color: rgba(102, 102, 102, 1);
          text-align: center;
          line-height: 1.6;
        }
      }
    }

    .image-section {
      z-index: 11;

      .image-section-1 {
        display: flex;

        .image-box {
          border-radius: 10px;
          margin: 20px;
          padding: 30px;

          .image-box-heading {
            align-items: center;
            padding-bottom: 20px;
            margin-bottom: 20px;

            .heading-icon {
              font-size: 55px;
              color: #32a0ef;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              padding: 8px;
            }

            .heading-text {
              margin-left: 10px;
              color: rgba(51, 51, 51, 1);
              font-size: 24px;
              font-weight: 600;
            }
          }

          .heading-para {
            font-size: 16px;
            color: rgba(102, 102, 102, 1);
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .blog-posts-container {
    padding: 60px 20px 20px 20px;
    background-color: rgba(255, 255, 255, 1);
    font-family: Arial, sans-serif;

    .header {
      text-align: center;

      .heading-testimonial-home {
        background-color: rgba(230, 245, 255, 1);
        width: min-content;
        margin: auto;
        border-radius: 20px;
        padding: 5px 10px;
        color: rgba(50, 160, 239, 1);
        font-weight: normal;
        font-size: 18px;
        white-space: nowrap;
      }

      h1 {
        margin-top: 10px;
        font-size: 2rem;
        color: rgba(51, 51, 51, 1);
      }
    }

    .latest-posts-section {
      margin-top: 20px;
      padding: 30px;
      text-align: center;

      .section-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: rgb(51, 51, 51);
        margin: auto;
        width: 87%;

        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
        }
      }

      .posts-grid {
        display: flex;
        gap: 20px;
        justify-content: center;
        justify-items: center;
        padding: 25px 80px;
        background-color: rgba(255, 255, 255, 1);
        flex-wrap: wrap;

        .large-post-outer {
          display: flex;
          flex: 0.6;
          justify-content: center;
        }

        .large-post {
          text-align: left;
          width: 50%;
          border-radius: 8px;
          overflow: hidden;
          padding: 10px;

          .post-image-placeholder {
            overflow: hidden;
            background-color: transparent;
            height: 290px;
            margin-bottom: 10px;
            border-radius: 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .post-date {
            color: #a5a5a5;
            font-size: 0.9rem;
          }

          .post-title {
            font-size: 1.1rem;
            margin-bottom: 10px;
          }

          .post-description {
            font-size: 0.9rem;
            color: #666;
          }
        }

        .small-posts {
          display: flex;
          flex-direction: column;
          gap: 0px;
          flex: 0.4;

          .small-post {
            display: flex;
            border-bottom: 1px solid #e0e0e0;
            align-items: center;

            .post-image-placeholder {
              display: flex;
              background-color: transparent;
              margin-right: 10px;
              border-radius: 5px;
              align-items: center;
              img {
                object-fit: cover;
              }
            }

            .post-date {
              color: #a5a5a5;
              font-size: 0.8rem;
              text-align: left;
            }

            .post-title {
              font-size: 1rem;
              text-align: left;
            }
          }

          .small-post:nth-last-child(1) {
            border-bottom: none;
          }
        }
      }
    }
  }

  .news-updates-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: rgba(255, 255, 255, 1);

    .header {
      text-align: center;

      h4 {
        color: #6d8fdd;
        font-weight: normal;
      }

      h1 {
        margin-top: 5px;
        font-size: 2rem;
      }
    }

    .latest-posts-section {
      padding: 30px;
      text-align: center;

      .section-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: rgb(51, 51, 51);
        margin: auto;
        width: 88%;
      }

      .posts-grid {
        display: flex;
        gap: 20px;
        justify-content: center;
        justify-items: center;
        padding: 25px 80px;
        flex-wrap: wrap;

        .large-post-outer {
          display: flex;
          flex: 0.6;
          justify-content: center;
          gap: 20px;
        }

        .large-post {
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
          background-color: rgba(240, 248, 255, 1);
          display: flex;
          text-align: left;

          .post-image-placeholder {
            overflow: hidden;
            margin-bottom: 10px;
            flex: 0.5;

            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
            }
          }

          .post-content {
            flex: 0.5;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .post-insights {
              color: #32a0ef;
              font-size: 0.9rem;
              text-align: left;
              font-weight: bold;
            }
          }

          .post-date {
            color: #a5a5a5;
            font-size: 0.9rem;
          }

          .post-title {
            font-size: 1.1rem;
            margin-bottom: 10px;
          }

          .post-description {
            font-size: 0.9rem;
            color: #666;
          }
        }

        .small-posts {
          display: flex;
          flex-direction: column;
          gap: 10px;
          flex: 0.4;

          .small-post {
            display: flex;
            border-radius: 8px;
            padding: 10px;
            align-items: center;

            .post-image-placeholder {
              display: flex;
              overflow: hidden;
              width: 100%;
              max-width: 156px;
              margin-right: 10px;
              border-radius: 5px;
              align-items: center;

              img {
                width: 100%;
              }
            }

            .post-date {
              color: #a5a5a5;
              font-size: 0.8rem;
              text-align: left;
            }

            .post-insights {
              color: #32a0ef;
              font-size: 0.9rem;
              text-align: left;
              font-weight: bold;
            }

            .post-title {
              font-size: 1rem;
              text-align: left;
            }
          }
        }
      }
    }
  }

  .upcoming-events-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background: linear-gradient(180deg, #ffffff 0%, #f1f9ff 100%);

    .header {
      text-align: center;

      h4 {
        color: #6d8fdd;
        font-weight: normal;
      }

      h1 {
        margin-top: 5px;
        font-size: 2rem;
      }
    }

    .latest-posts-section {
      margin: 30px;
      text-align: center;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        margin: 10px;
      }

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
        margin: 10px;
      }

      .section-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: rgb(51, 51, 51);
        margin: auto;
        width: 88%;

        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
        }
      }

      .card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        justify-items: center;
        padding: 25px 80px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
          padding: 10px;
        }

        @media (max-width: 480px) {
          grid-template-columns: 1fr;
          padding: 10px;
        }
      }

      .card {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        background-color: white;

        .card-image {
          width: 100%;
          background-color: #ddd;

          img {
            width: 100%;
            object-fit: cover;
            align-items: center;
            display: flex;
          }
        }

        .card-content {
          padding: 20px;

          .date-section-outer {
            display: flex;
          }

          .date-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;
            margin-top: 18px;

            .month {
              color: #1a73e8;
              font-size: 14px;
              font-weight: bold;
            }

            .day {
              font-size: 32px;
              font-weight: bold;
            }
          }

          .description-outer {
            text-align: left;
            padding: 0px 10px;
          }

          .event-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }

          .event-description {
            font-size: 14px;
            color: #555;
            margin-bottom: 16px;
          }

          .event-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 14px;
            color: #777;
            text-align: left;

            .event-info-sub {
              display: flex;
              align-items: center;

              .event-info-icon {
                margin-right: 5px;
                color: rgba(159, 159, 159, 1);
              }

              .event-info-text {
                color: rgba(159, 159, 159, 1);
              }
            }
          }
        }
      }

      .cta-button {
        background-color: #4299e1; // Light blue button
        color: #fff;
        padding: 15px 35px;
        border: none;
        border-radius: 50px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        margin: auto;
        align-items: center;
        transition: background-color 0.3s ease;
        gap: 5px;

        &:hover {
          background-color: #2b6cb0; // Darker blue on hover
        }

        .search-icon {
          font-weight: 800;

          img {
            width: 14px;
          }
        }
      }
    }
  }

  .main-testimonial-posts-container {
    background-color: rgba(255, 255, 255, 1);
    margin: auto;

    .testimonial-posts-container {
      padding: 20px;
      font-family: Arial, sans-serif;
      overflow: hidden;

      .header {
        text-align: center;

        .heading-testimonial-home {
          background-color: rgba(230, 245, 255, 1);
          width: min-content;
          margin: auto;
          border-radius: 10px;
          padding: 5px 10px;

          span {
            white-space: nowrap;
            color: rgba(50, 160, 239, 1);
            font-weight: normal;
            font-size: 18px;
          }
        }

        .sub-heading-testimonial-home {
          font-size: 35px;
          font-weight: 700;
          width: 60%;
          margin: auto;
          margin-top: 20px;
          color: rgba(51, 51, 51, 1);

          .sub-heading-part-one-testimonial-home {
            font-size: 35px;
            font-family: Plus Jakarta Sans;
            background: linear-gradient(90deg, #11042b 0%, #32a0ef 100%);
            font-weight: 800;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .latest-posts-section {
        margin-top: 20px;
        text-align: center;

        .section-title {
          display: flex;
          align-items: center;
          font-size: 20px;
          margin: 25px;
        }

        .section-title::before,
        .section-title::after {
          content: "";
          flex-grow: 1;
          background-color: #6d8fdd;
          height: 1.5px;
          margin: 0 15px;
        }

        .testimonial-slider {
          width: 100%;
          overflow: hidden;
          padding: 20px;
          position: relative;

          .slider-track {
            animation: scroll 5s linear infinite;
            display: flex;
            margin: 20px auto;
            justify-content: center;
            animation: scroll 20s linear infinite;
            position: relative;

            // Animation for auto-move

            .testimonial {
              flex: 0 0 225px; // Adjust card width
              padding: 20px;
              border: 1px solid rgba(234, 234, 234, 1);
              margin: 0 5px;
              border-radius: 8px;
              background-color: white;
              text-align: left;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .quote {
                font-size: 14px;
                margin-bottom: 20px;
                color: #555;
                position: relative;
                padding-left: 30px;

                &::before {
                  content: "“";
                  font-size: 64px;
                  position: absolute;
                  left: 6px;
                  top: -18px;
                  color: #1a73e8;
                }
              }

              .user-info {
                display: flex;
                align-items: center;

                img {
                  border-radius: 50%;
                  margin-right: 10px;
                }

                .details {
                  display: flex;
                  align-items: center;

                  h4 {
                    margin: 0;
                    font-size: 14px;
                  }

                  .rating {
                    color: #ffd700;
                    margin-left: 10px;
                  }

                  .designation {
                    font-size: 12px;
                    color: #777;
                  }
                }
              }
            }
          }

          .slider-track::before,
          .slider-track::after {
            content: "";
            position: absolute;
            top: 0;
            width: 17%; // Adjust width as needed
            height: 100%; // Match the height of the track
            z-index: 1; // Ensure shadows are above the background but below the track

            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 0.71)
            ); // Left shadow
          }

          .slider-track::before {
            left: -50px; // Position on the left
          }

          .slider-track::after {
            right: -50px; // Position on the right
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 0)
            ); // Right shadow
          }
        }
      }
    }
  }

  .main-connect-section {
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0px 0px 100px 10px;
    align-content: center;
    text-align: center;
    font-family: sans-serif;

    .connect-section {
      background-color: rgba(17, 4, 43, 1);
      color: #fff;
      text-align: center;
      padding: 50px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: relative;
      width: 70%;
      border-radius: 20px;
      margin: auto;

      .icon {
        padding: 20px;
        border-radius: 50%;
        display: inline-block;
        img {
          width: 50px;
        }

        i {
          font-size: 40px;
          color: #fff;
        }
      }

      .connect-section-heading {
        font-size: 32px;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
        margin-bottom: 30px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        color: #bbbbbb;
      }

      .cta-button {
        background-color: #4299e1; // Light blue button
        color: #fff;
        padding: 15px 30px;
        border: none;
        border-radius: 50px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #2b6cb0; // Darker blue on hover
        }
      }

      // Responsive adjustments
      @media (max-width: 768px) {
        h2 {
          font-size: 24px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .section-1 {
      .background-container {
        .left-bg,
        .right-bg {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .trusted-partners-container {
      padding: 0px;

      .partners-logos-wrapper {
        .partners-logos {
          gap: 40px; // Adjusted for smaller screen spacing
        }

        .partner-logo {
          width: 120px; // Adjusted size for logos
        }
      }
    }

    .cybersecurity-partner-container {
      flex-direction: column; // Stack text and image sections vertically
      align-items: center;

      .text-section,
      .image-section {
        width: 75%; // Full-width for each section
        padding: 0px;
      }

      .image-box {
        margin: auto;
        width: 65% !important; // Reduce image size for smaller screens
      }
    }

    .section-4-container {
      flex-direction: column;

      .text-section,
      .image-section {
        width: 90%;
        padding: 0px;
      }
    }

    .blog-posts-container,
    .news-updates-container {
      padding: 10px;

      .posts-grid {
        flex-direction: column;

        .large-post-outer {
          flex-direction: column;
          width: 100% !important;
        }

        .large-post,
        .small-posts {
          width: 100% !important;
        }
      }
    }

    .upcoming-events-container {
      .card-container {
        grid-template-columns: 1fr; // Stack cards vertically
        padding: 10px !important;
      }

      .card {
        width: 100%;
      }
    }

    .main-testimonial-posts-container {
      padding: 20px;

      .testimonial-slider {
        .testimonial {
          flex-direction: column;
          padding: 15px;
        }
      }
    }

    .main-connect-section {
      .connect-section {
        width: 100%;
        padding: 20px;
      }
    }
  }

  // Media queries for mobile viewports
  @media (max-width: 768px) {
    .section-1 {
      .background-container {
        .left-bg,
        .right-bg {
          width: 100%;

          img {
            height: auto;
          }
        }
      }
    }

    .trusted-partners-container {
      padding: 0px;

      .partners-logos-wrapper {
        .partners-logos {
          gap: 20px; // Adjusted for smaller screens
        }

        .partner-logo {
          width: 100px; // Adjust logo size
        }
      }
    }

    .cybersecurity-partner-container {
      flex-direction: column;

      .text-section,
      .image-section {
        width: 100%;
        padding: 0px;
      }

      .image-box {
        width: 90%; // Further reduce image size for mobile
      }
    }

    .section-4-container {
      flex-direction: column;

      .text-section,
      .image-section {
        width: 100%;
        padding: 10px;

        .image-section-1 {
          display: block;
        }
      }
    }

    .blog-posts-container,
    .news-updates-container {
      padding: 5px;

      .posts-grid {
        flex-direction: column;
        padding: 34px 10px !important;

        .large-post-outer {
          flex-direction: column;
          width: 100% !important;
        }

        .large-post,
        .small-posts {
          width: 100% !important;
        }
      }
    }

    .upcoming-events-container {
      .card-container {
        grid-template-columns: 1fr; // Single column for mobile
        padding: 10px;
      }

      .card {
        width: 100%;
      }
    }

    .main-testimonial-posts-container {
      padding: 20px;

      .testimonial-slider {
        .testimonial {
          flex-direction: column;
          padding: 10px;
        }
      }
    }

    .main-connect-section {
      .connect-section {
        width: 80%;
        padding: 15px;
      }
    }
  }

  // Media queries for small mobile viewports
  @media (max-width: 480px) {
    .section-1 {
      .background-container {
        .left-bg,
        .right-bg {
          width: 100%;

          img {
            height: auto;
          }
        }
      }

      .carousel-wrapper {
        .tab1 {
          .tab1-top {
            .tab1-top-inner {
              .line1 {
                background: transparent;
                border-radius: 50px;
                width: max-content;
                padding: 0px 10px;
                font-size: 14px;
                color: #666666;
                border: 1px solid #32a0ef;
                margin: auto;
                display: flex;
                align-items: center;
              }
              .line2 {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .trusted-partners-container {
      padding: 0px;
      margin: auto;

      .partners-logos-wrapper {
        .partners-logos {
          gap: 10px; // Adjusted for smaller screens
        }

        .partner-logo {
          width: 80px; // Smaller logo size for small mobile
        }
      }
    }

    .cybersecurity-partner-container {
      flex-direction: column;

      .text-section,
      .image-section {
        width: 100%;
        padding: 5px;
      }

      .image-box {
        width: 100%;
      }
    }

    .section-4-container {
      flex-direction: column;

      .text-section,
      .image-section {
        width: 100%;
        padding: 5px;
      }
    }

    .blog-posts-container,
    .news-updates-container {
      padding: 5px;

      .posts-grid {
        flex-direction: column;
        padding: 10px !important;

        .large-post-outer {
          flex-direction: column;
          width: 100% !important;
        }

        .large-post,
        .small-posts {
          width: 100% !important;
        }
      }
    }

    .upcoming-events-container {
      .card-container {
        grid-template-columns: 1fr; // Single column for mobile
        padding: 10px;
      }

      .card {
        width: 100%;
      }
    }

    .main-testimonial-posts-container {
      padding: 10px;

      .testimonial-slider {
        .testimonial {
          flex-direction: column;
          padding: 5px;
        }
      }
    }

    .main-connect-section {
      .connect-section {
        width: 90%;
        padding: 10px;
        padding-bottom: 20px;
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .home {
    width: 100%;
  }
}

@media (min-width: 643px) and (max-width: 767.98px) {
}
