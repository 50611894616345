.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    background: rgb(255 255 255 / 76%);
    z-index: 100;
    backdrop-filter: blur(4px);

    .navbar-left {
        .logo {
            height: 40px;
        }
    }

    .navbar-center {
        display: flex;
        align-items: center;

        .nav-list {
            display: flex;
            gap: 20px;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                .nav-link {
                    text-decoration: none;
                    font-size: 15px;
                    color: #585858;

                    &:hover {
                        color: #007bff;
                    }

                    &.active {
                        color: #007bff;
                        border-bottom: 2px solid #007bff;
                    }
                }
            }
        }

        // Open menu for small screens
        &.open {
            position: absolute;
            top: 67px;
            right: 9px;
            width: 30%;
            background-color: rgb(248 248 248);
            text-align: center;
            animation: slideIn 0.3s ease-out;
            border-radius: 10px;
            border: 1px solid #e5e5e5;

            .nav-list {
                flex-direction: column;
                gap: 15px;
                align-items: center;

                li {
                    padding: 15px 20px;
                    text-align: right;
                }
            }
        }
    }

    .navbar-right {
        .download-app-btn {
            background-color: #007bff;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 20px;
            cursor: pointer;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    // Hamburger icon styling
    .hamburger {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 20px;

        .hamburger-icon {
            font-size: 32px;
        }
    }
}

// Keyframe for sliding animation
@keyframes slideIn {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}

// Media Queries for responsive navbar
@media (max-width: 720px) {
    .navbar {
        padding: 5px 10px;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .navbar-center {
            display: none; // Hide the menu initially

            &.open {
                display: block;
                width: 35%;
            }
        }

        .navbar-right {
            display: none; // Hide the right side button in mobile view
        }

        .hamburger {
            display: block; 
                font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 40px;
            .hamburger-icon {
                font-size: 22px;
            }
        }
    }
}

@media (min-width: 721px) {
    .navbar-center {
        display: flex;

        .nav-list {
            flex-direction: row;
        }
    }

    .hamburger {
        display: none; // Hide hamburger menu on larger screens
    }
}


.hamburger-hide {
    display: none !important;
}