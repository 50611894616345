.flagship-product {
    padding: 50px 100px;
    text-align: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7385547969187676) 0%, rgba(242, 249, 255, 1) 100%);

    @media (max-width: 768px) {
        padding: 50px 10px;
    }

    .flagship-sub-heading {
        background: #E6F5FF;
        color: #32A0EF;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
        font-size: 16px;
    }

    span {
        font-size: 40px;
        font-weight: 600;
        color: #32A0EF;
    }

    h2 {
        font-size: 48px;
        margin-bottom: 2rem;
        color: #333;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // max-width: 1200px;
        margin: 0 auto;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__text {
        max-width: 50%;
        text-align: left;

        p {
            font-size: 1.2rem;
            margin-bottom: 1.5rem;
            color: #555;
        }

        ul {
            list-style: none;
            margin-left: 1.5rem;
            border-left: 2px solid #32A0EF;

            li {

                display: flex;
                align-items: center;
                margin-bottom: 12px;
            }
            .icon {
                width: 42px; 
                height: 41px; 
                margin-right: 8px; 
              }
        }

        @media (max-width: 768px) {
            max-width: 100%;
            margin-bottom: 2rem;
        }
    }

    &__image {
        max-width: 40%;

        img {
            width: 100%;
            border-radius: 10px;
            // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
}