.main-container-footer {
  .newsletter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    margin: auto;
    background: rgb(236, 247, 255);
    padding: 20px;

    .subscribe-heading-footer {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 90px;

      .subscribe-firstpart-heading-footer {
        font-size: 25px;
        color: rgba(51, 51, 51, 1);
        font-weight: 600;
        font-family: sans-serif;
      }

      .subscribe-secondpart-heading-footer {
        font-size: 65px;
        font-family: Plus Jakarta Sans;
        background: linear-gradient(90deg, #11042B 0%, #32A0EF 100%);
        font-weight: 800;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }

    .newsletter-form {
      display: flex;
      margin: auto;
      justify-content: center;

      input {
        padding: 10px;
        border-radius: 15px;
        border: none;
        width: 355px;
        outline: none;
        margin-right: 10px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(50, 160, 239, 1);
        color: rgba(171, 171, 171, 1);
        font-weight: 400;
      }

      input::placeholder {
        color: rgba(171, 171, 171, 1);
        opacity: 1;
      }

      button {
        padding: 10px 20px;
        border-radius: 15px;
        background-color: #4a90e2;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          background-color: #357ab9;
        }
      }
    }
  }

  .footer-btnup {
    // display: flex;
    background: rgba(17, 4, 43, 1);
    align-items: center;
    justify-content: center;
    position: relative;

    .footer {
      background: rgba(17, 4, 43, 1);
      color: white;
      padding: 70px;
      text-align: center;

      .footer-content {
        display: flex;
        font-family: Plus Jakarta Sans;
        padding: 20px;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;

        .footer-logo {
          width: 20%;

          p {
            color: rgba(255, 255, 255, 0.6);
            margin-top: 10px;
          }
        }

        .quick-links,
        .contact-info,
        .social-links {
          width: 20%;
          color: rgba(255, 255, 255, 0.8);

          h3 {
            margin-bottom: 15px;
            font-size: 18px;
          }

          .contact-email {
            color: rgba(255, 255, 255, 0.6);
            line-height: 30px;
            text-decoration: none;
          }

          .contact-address {
            color: rgba(255, 255, 255, 0.6);
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              margin-bottom: 10px;

              a {
                color: rgba(255, 255, 255, 0.6);
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .featured-badge {
            white-space: nowrap;
            border: 1px solid var(--primary-antro, rgba(50, 160, 239, 1));
            background: rgba(213, 238, 255, 1);
            color: rgba(50, 160, 239, 1);
            padding: 2px 6px;
            border-radius: 10px;
            font-size: 12px;
            margin-left: 5px;
          }

          .icons {
            a {
              color: white;
              font-size: 20px;
              margin: 0 10px;
              text-decoration: none;

              &:hover {
                color: #4a90e2;
              }
            }
          }
        }
      }

      .footer-bottom {
        background: rgb(17, 4, 43);
        color: rgba(255, 255, 255, 0.6);
        padding: 35px 0px 0px 0px;
        margin-top: 40px;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        font-family: Plus Jakarta Sans;
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .footer-links {
          a {
            color: rgba(255, 255, 255, 0.6);
            margin: 0 10px;
            text-decoration: none;
            font-family: Plus Jakarta Sans;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .toggle-up-footer {
      padding-top: 29%;
      margin-right: 20px;
      position: absolute;
      bottom: 35px;
      right: 20px;

      img {
        width: 40px;
      }
    }
  }

  // Small devices (max-width: 576px)
  @media (min-width:320px) and (max-width: 576px) {
    .newsletter-section {
      flex-direction: column;
      text-align: center;
      padding: 0px;
      display: block;

      .subscribe-heading-footer {
        margin-left: 0;

        .subscribe-firstpart-heading-footer {
          font-size: 18px;
        }

        .subscribe-secondpart-heading-footer {
          font-size: 35px;
        }
      }

      .newsletter-form {
        flex-direction: column;

        input {
          width: 80%;
          margin: 10px auto;
          // margin-right: 0;
          margin-bottom: 10px;
        }

        button {
          width: min-content;
          margin: auto;
        }
      }
    }

    .footer-content {
      flex-direction: column;
      align-items: center;

      .footer-logo,
      .quick-links,
      .contact-info,
      .social-links {
        width: 100% !important;
        text-align: center;
        margin-bottom: 20px;
      }

      .icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }

    .footer-bottom {
      flex-direction: column;
      font-size: 14px;
      gap: 5px;
    }

    .toggle-up-footer {
      padding-top: 5%;

      img {
        width: 30px;
      }
    }
  }

  // Medium devices (min-width: 576px and max-width: 767.98px)
  @media (min-width: 576px) and (max-width: 767.98px) {

    .newsletter-section {
      flex-direction: column;
      padding: 0px;
      display: block;

      .subscribe-heading-footer {
        .subscribe-firstpart-heading-footer {
          font-size: 20px;
        }

        .subscribe-secondpart-heading-footer {
          font-size: 45px;
        }
      }

      .newsletter-form {
        input {
          // width: 90%;
        }

        button {
          // width: 90%;
        }
      }
    }

    .footer-content {
      flex-direction: column;
      align-items: center;

      .footer-logo,
      .quick-links,
      .contact-info,
      .social-links {
        width: 100% !important;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .footer-bottom {
      font-size: 14px;
      text-align: center;
      padding: 20px 0;
      margin-top: 0px !important;
    }
  }

  .toggle-up-footer {
    padding-top: 20%;
  }
}
@media (max-width: 575.98px) {
  .main-container-footer{
    width: 100%;
    .footer {
      background: rgb(17, 4, 43);
      color: white;
      padding: 20px;
      text-align: center;
  }
  }
}

@media (min-width: 576px) and (max-width: 642.98px) {
  .main-container-footer{
    width: 100%;
  }
}

@media (min-width: 643px) and (max-width: 767.98px) {
  .main-container-footer{
    width: 100%;
    .newsletter-section{
      .subscribe-heading-footer{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 118px;
      }
    }
  }
}
// Tablet devices (min-width: 768px and max-width: 991.98px)
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container-footer {
    padding: 0px;
    width: 100%;

    .newsletter-section {
      .subscribe-heading-footer {
        .subscribe-firstpart-heading-footer {
          font-size: 22px;
        }

        .subscribe-secondpart-heading-footer {
          font-size: 50px;
        }
      }

      .newsletter-form {
        input {
          width: 70%;
        }

        button {
          // width: 30%;
        }
      }
    }

    .footer-content {
      flex-direction: row;
      align-items: flex-start;

      .footer-logo,
      .quick-links,
      .contact-info,
      .social-links {
        width: 100%;
      }

      .icons {
        justify-content: flex-start;
      }
    }

    .footer-bottom {
      font-size: 14px;
    }
  }

  .toggle-up-footer {
    padding-top: 10%;
  }
}

@media (max-width: 1024px) {
  .main-container-footer {
    .newsletter-section {
      padding: 20px;
    }
  }
}