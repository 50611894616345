.services {
    // display: flex;
    padding: 50px 100px;
    .services h2 {
      font-size: 42px;
    }
    @media (max-width: 768px) {
        padding: 50px 10px;
      }

    .services-sub-heading {
            background: #E6F5FF;
            color:#32A0EF;
            width: min-content;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 15px;
            margin: auto;
            font-size: 16px;
    }

  
    &__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
  
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  
    &__image {
      flex: 1;
      margin-right: 2rem;
  
      img {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
  
      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  
    &__accordion {
        text-align: left;
      flex: 2;
    //   max-width: 600px;
  
      h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
  
      .service-item {
        background-color: #EEF8FF;
        // border-radius: 10px;
        margin-bottom: 1rem;
        border-left: 2px solid #32A0EF;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        .service-title {
          padding: 1.5rem;
          color: #32A0EF;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: bold;
          background-color: #EEF8FF;
  
          &:hover {
          }
        }
  
        .service-description {
          padding: 1rem 1.5rem;
          font-size: 1rem;
          color: #555;
  
          p {
            white-space: pre-wrap;
          }
        }
      }
  
      .expanded .service-title {
      }
    }
  }
  