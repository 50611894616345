// Variables
$primary-color: #007bff;
$text-color: #666;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.2);

.page_wrapper {
  .details_section {
    .container {
      display: flex;
      width: 100%;
      margin-top: 100px;
      .sub-section-left {
        width: 60%;
        .inner-sub-section-left {
          width: 80%;
          text-align: center;
          margin: auto;
          display: flex;
          flex-direction: column;
          margin-top: 100px;
          .banner_text {
            .type_wrap {
              .typed {
              }
            }
            h1 {
              font-size: 30px;
              span {
                color: #007bff;
              }
            }
            p {
              font-size: 20px;
              color: $text-color;
            }
          }
          .used_app {
            p {
              color: #007bff;
              font-size: 20px;
            }
          }
        }
      }
      .sub-section-right {
        width: 40%;
        .inner-sub-section-right {
          width: 80%;
          text-align: center;
          margin: auto;
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .banner_slider {
            position: relative;
            .item {
              margin-bottom: 20px;
              margin-top: 15px;
              .card {
                background: #ffffff;
                border-radius: 8px;
                box-shadow: $box-shadow;
                padding: 20px;
                text-align: center;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                position: relative;
                overflow: hidden;
              
                &:hover {
                  transform: translateY(-10px);
                  box-shadow: $box-shadow-hover;
                }
              
                .store-links {
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap; // Allows items to wrap to the next line if needed
                  margin: 15px 0;
                  padding: 2px 5px;
                  a {
                    text-decoration: none; // Removes underline
                    color: inherit; // Ensures the text color matches its parent
                  }
                  a:hover {
                    text-decoration: none; // Keeps underline removed even on hover
                  }
                  
                  .store-button {
                    background-color: black;
                    color: white;
                    padding: 12px;
                    border-radius: 40px;
                    margin: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    display: flex;
                    align-items: center; // Align content vertically
                    justify-content: center; // Center content horizontally
                    border: 1px solid black;
                    &:hover {
                      background-color: #000000;
                      transform: scale(1.1);
                    }
                  }
              
                  .store-button-icon {
                    display: flex;
                    justify-content: start;
                  }
              
                  .store-button-title {
                    display: grid;
                    margin-left: 8px;
                  }
              
                  .store-button-text {
                    font-size: 12px;
                  }
              
                  .store-button-name {
                    font-size: 18px;
                  }
                }
              
                
                .card-icon {
                  background: #f0f0f0;
                  border-radius: 50%;
                  display: inline-block;
                  padding: 15px;
                  box-shadow: $box-shadow;
                  margin-bottom: 15px;
                  img {
                    width: 20px;
                    height: 20px;
                  }
                  
                }
                h3 {
                  font-size: 1.25rem;
                  margin-bottom: 10px;
                }
                p {
                  font-size: 1rem;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .page_wrapper .details_section .container {
    display: block;
    .sub-section-left {
      width: 100%;
    }
    .sub-section-right {
      width: 100%;
    }
  }
}

// Media query for smaller screens
@media (max-width: 320px) {
  .store-links {
    flex-direction: column; // Stack buttons vertically
    align-items: center; // Center the buttons
  }

  .store-button {
    width: 80%; // Adjust button width to fit smaller screens
  }
}
