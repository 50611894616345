.saas {
  text-align: center;
  padding: 50px 100px;

  @media (max-width: 768px) {
    padding: 50px 10px;
  }

  .saas_grid_outer {
    border: 1px solid #32A0EF;
    padding: 10px;
    border-radius: 10px;

    .saas__grid {
      .bg-cover {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      padding: 0rem 0;
      background: #e0f2ff;
      border-radius: 10px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }
    }

    .milestone-sub-heading-2 {
      font-size: 16px;
      width: 60%;
      margin: 20px auto;
    }

    .saas_item {
      //   background-color: #f9f9f9;
      padding: 2rem 0px;
      //   border-radius: 8px;
      //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}