.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  position: relative;
  height: 500px;
  background: white;
  @media (max-width: 768px) {
    padding: 50px 10px;
  }

  &__content {
    z-index: 1;
    text-align: left;
    max-width: 50%;
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      color: #666666;
      font-weight: 400;

    }

    .powered-by {
      background-color: #32a0ef;
      color: white;
      width: min-content;
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 15px;
    }
  }

  &__image {
    position: absolute;
    opacity: 1;
    will-change: auto;
    transform: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    img {
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    //   align-items: flex-start;
    .service-header__image {
      height: auto;
      width: 100%;
      position: relative;
      img {
        height: auto;
        width: 100%;
      }
    }

    &__content {
      max-width: 100%;
      h1 {
        font-size: 2rem;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .service-header__content {
    h1 {
      width: 75%;
    }
  }
}
