.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(23, 18, 18, 0.768);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .modal-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    height: 260px;
    text-align: center;
    margin-top: 50px;
    .modal-header {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      position: relative;
      .modal-icon {
        width: 48px;
        height: 48px;
      }
      .heading {
        font-size: 18px;
        color: rgba(24, 29, 39, 1);
        font-weight: 600;
      }
      .close-modal-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
    color: #756f6f;
    cursor: pointer;
    font-weight: 400;
      }
    }
    .modal-content {
      font-size: 16px;
      color: rgba(83, 88, 98, 1);
      font-weight: 400;
      text-align: left;
      width: 95%;
    }

    .error-modal-deletion {
      color: red;
      font-size: 12px;
    }

    .modal-actions {
      display: flex;
      justify-content: space-between;
      width: 95%;
      gap: 20px;
      margin-top: 45px;
      .modal-cancel {
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(213, 215, 218, 1);
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(65, 70, 81, 1);
        width: 50%;
      }

      .modal-confirm {
        background-color: rgba(217, 45, 32, 1);
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        width: 50%;
      }
    }
  }
}

.modal-cancel,
.modal-confirm {
  padding: 10px 20px;
  border: none;
  background-color: #f1f1f1;
  cursor: pointer;
}

.modal-confirm {
  background-color: #4caf50; // Green background for confirm button
  color: white;
}

.modal-cancel {
  background-color: #f44336; // Red background for cancel button
  color: white;
}


