.main-update-username {
  background-color: #ffffff;
  margin: 50px auto;
  border-radius: 15px;
  border: 1px solid #ddd;
  text-align: center;
  padding: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 90%;
  max-width: 500px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 100px;
    }
  }

  .lock-icon {
    width: 100%;
    height: 100px;
  }

  .container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;

    h2 {
      margin-bottom: 20px;
      color: rgba(0, 171, 227, 1);
      font-size: 22px;
      font-weight: 700;
      border-radius: 5px;
      padding: 10px;
    }

    .form-corporate-newUsername {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .input-wrapper {
        position: relative;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 10px;
        width: 100%;
        max-width: 350px;

        .username-input {
          font-size: 14px;
          outline: none;
          border: none;
          color: black;
          font-weight: 500;
          width: 100%;
          padding: 4px;
          box-sizing: border-box;
        }
      }

      .main-submit-btn {
        width: 40%;
        max-width: 280px;

        button {
          display: flex;
          justify-content: center;
          padding: 12px;
          border-radius: 10px;
          background-color: rgba(0, 171, 227, 1);
          border: none;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          width: 100%;
          text-decoration: none;
          outline: none;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            background-color: rgba(0, 150, 200, 1);
            box-shadow: 0 4px 6px rgba(0, 171, 227, 0.3);
          }

          &:focus {
            outline: 2px solid rgba(0, 171, 227, 0.7);
          }

          &:disabled {
            font-weight: 700;
            color: #fff;
            background-color: rgb(129, 128, 128);
            cursor: not-allowed;
          }
        }
      }
    }

    a {
      text-decoration: none;
    }
  }
}

.input-wrapper.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 5px;
}

.suggestions {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;

  .suggestion {
    color: rgba(0, 171, 227, 1);
    cursor: pointer;
    margin: 5px 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.visit-website {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #f0f8ff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

.visit-website-content {
  text-align: center;
  max-width: 600px;
}

.visit-website-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.visit-website-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #3c93fb;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.visit-website-button:hover {
  background-color: #2a6bbf;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.visit-website-button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgba(60, 147, 251, 0.4);
}

.visit-website-button:active {
  background-color: #1e4e8c;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

.button-icon {
  font-size: 20px; 
  line-height: 1;
}

@media (max-width: 768px) {
  .visit-website-text {
      font-size: 16px; 
  }

  .visit-website-button {
      font-size: 14px;
      padding: 10px 20px;
  }
}


@media (max-width: 600px) {
  .main-update-username {
    width: 95%;
    margin: 20px auto;
  }

  .container {
    h2 {
      font-size: 20px;
    }
  }

  .input-wrapper {
    width: 100%;
  }

  .main-submit-btn {
    width: 75%; 
  }

  .main-submit-btn button {
    font-size: 14px;
    padding: 10px;
  }
}


