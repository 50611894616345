.profile-manager {
    padding: 20px;
    font-family: Arial, sans-serif;

    .user-not-found-image {
        width: 33%;
        margin: auto;

        img {
            width: 100%;
        }
    }

    .message-box {
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        width: 54vw;

        p {
            color: grey;
        }
    }

    h2 {
        color: #333;
    }

    .group-card {
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        margin: 10px 0;
        background: linear-gradient(180deg, rgba(227, 243, 255, 1) 1%, rgba(218, 238, 251, 1) 32%, rgba(246, 251, 255, 1) 52%);

        h3 {
            margin-top: 0;
            color: #666;
        }

        .details-card {
            p {
                margin: 5px 0;

                strong {
                    color: #333;
                }
            }
        }

        .group-details-card-inner {
            text-align: center;
            padding: 20px 0px;
            background-blend-mode: screen;

            .group-details-card {
                width: 300px;
                height: 220px;
                margin: auto;
                position: relative;
                background: white;
                border-radius: 5px;

                .group-details-card-a {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-position: top;
                    background-repeat: no-repeat;

                    .group-icon {
                        font-size: 26px;
                        display: flex;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        margin: auto;
                        color: #31a0ef;
                        border: 1px solid #31a0ef;
                        background: white;
                        border-radius: 50%;
                        padding: 5px;

                        svg {
                            margin: auto;
                        }
                    }

                    .group-name-details {
                        margin: auto;
                        width: 80%;

                        .name {
                            border-bottom: 2px solid #31a0ef;
                            padding: 15px;
                        }

                        .company {
                            margin-top: 15px;
                            font-size: 15px;
                        }
                    }

                    .group-location {
                        margin: auto;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .warning-message {
        background: linear-gradient(180deg, hsla(60, 100%, 89%, 1) 0%, hsla(0, 0%, 100%, 1) 100%);
        border: 1px solid #ffeeba;
        // color: #856404;
        padding: 10px;
        border-radius: 5px;
        margin: 10px 0;
        display: flex;
        align-items: center;

        svg {
            font-size: 30px;
            color: #ffcc01;
        }

        div {
            margin: 5px;
        }

        .sub-line {
            color: grey;
        }
    }

    .functional-btns {
        display: flex;
        justify-content: end;
        gap: 10px;

        .functional-button {
            border: none;
            border-radius: 4px;
            padding: 10px 20px;
            margin: 5px;
            font-weight: bold;
            cursor: pointer;

            &.cancel {
                background-color: #ccc;
                color: #333;
            }

            &.switch,
            &.create {
                background-color: #31a0ef;
                color: white;
                white-space: nowrap;

                &.disabled {
                    background: grey;
                    pointer-events: none;
                }

                .personal-info-label-data {
                    label {
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .personal-info-data {
                        color: grey;
                    }
                }
            }

        }
    }

    .button {
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        margin: 5px;
        cursor: pointer;
        font-size: 14px;

        &.cancel {
            background-color: #ccc;
            color: #333;
        }

        &.switch,
        &.create {
            background-color: #007bff;
            color: white;
        }
    }

    .create-profile,
    .switch-group {
        width: 63vw;
        margin: auto;
        text-align: left;

        .create-profile-heading {
            text-align: center;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }

        .create-profile-subheading {
            text-align: center;
            margin-bottom: 40px;
        }
    }

    .group-details-card-outer {
        .group-details-card-inner {
            text-align: center;
            padding: 20px 0px;
            background-blend-mode: screen;

            .group-details-card {
                width: 300px;
                height: 220px;
                margin: auto;
                position: relative;
                background: white;
                border-radius: 5px;

                .group-details-card-a {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-position: top;
                    background-repeat: no-repeat;

                    .group-icon {
                        font-size: 26px;
                        display: flex;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        margin: auto;
                        color: #31a0ef;
                        border: 1px solid #31a0ef;
                        background: white;
                        border-radius: 50%;
                        padding: 5px;

                        svg {
                            margin: auto;
                        }
                    }

                    .group-name-details {
                        margin: auto;
                        width: 80%;

                        .name {
                            border-bottom: 2px solid #31a0ef;
                            padding: 15px;
                        }

                        .company {
                            margin-top: 15px;
                            font-size: 15px;
                        }
                    }

                    .group-location {
                        margin: auto;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .personal-information,
    .location-information {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 22px;
        margin-left: 10px;

        input {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
        }

        .personal-info-outer {
            .personal-info-label-data-outer {
                margin: 10px auto;

                .personal-info-label-data {
                    label {
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .personal-info-data {
                        color: grey;

                        .personal-info-outer {
                            padding: 10px;
                            border: 1px solid #ebebeb;
                            border-radius: 10px;
                            background: black;

                            .personal-info-label-data {
                                flex: 1 1 45%;

                                label {
                                    font-size: 15px;
                                    font-weight: bold;
                                }

                                .personal-info-data {
                                    color: grey;
                                    width: 100%;
                                    margin: 10px auto;
                                }
                            }
                        }

                        .location-information-inner {
                            padding: 10px;
                            border: 1px solid #ebebeb;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }

        .functional-btns {
            display: flex;
            justify-content: end;
            gap: 10px;

            .functional-button {
                border: none;
                border-radius: 4px;
                padding: 10px 20px;
                margin: 5px;
                font-weight: bold;
                cursor: pointer;

                &.cancel {
                    background-color: #ccc;
                    color: #333;
                }

                &.switch,
                &.create {
                    background-color: #31a0ef;
                    color: white;

                    &.disabled {
                        background: grey;
                        pointer-events: none;
                    }

                    .personal-info-label-data {
                        label {
                            font-size: 15px;
                            font-weight: bold;
                        }

                        .personal-info-data {
                            color: grey;
                        }
                    }
                }

            }
        }
        select {
            width: 100%;
            border: 1px solid grey;
            border-radius: 3px;
            padding: 5px;
        }
    }

    .button-group {
        display: flex;
        justify-content: flex-end;
        margin-right: 30px;
    }
}

@media screen and (max-width: 360px) {
    .personal-information {
        .personal-info-outer {

            .personal-info-label-data {
                .personal-info-data {
                    width: 100% !important;
                }
            }
        }
    }

    .profile-manager {
        padding: 10px;

        .user-not-found-image {
            width: 100%;
        }

        .message-box {
            width: 100%;
        }

        .group-card {
            padding: 10px;
        }

        .button {
            width: 100%;
            font-size: 12px;
            padding: 6px;
        }

        .create-profile,
        .switch-group {
            width: 100%;
        }

        .group-details-card {
            width: 250px !important;
            height: 250px !important;
        }

        .personal-information,
        .location-information {
            input {
                font-size: 12px;
                padding: 8px;
            }
        }
    }
}



@media screen and (min-width: 361px) and (max-width: 480px) {
    .personal-information {
        .personal-info-outer {

            .personal-info-label-data {
                .personal-info-data {
                    width: 100% !important;
                }
            }
        }
    }

    .profile-manager {
        padding: 15px;

        .user-not-found-image {
            width: 70%;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .message-box {
            padding: 10px;
            margin: 10px 0;
            border-radius: 5px;
            width: 85vw;

            p {
                color: grey;
                font-size: 14px;
            }
        }

        h2 {
            font-size: 20px;
            color: #333;
        }

        .group-card {
            padding: 15px;
            margin: 10px 0;
            background-color: #f9f9f9;

            h3 {
                font-size: 18px;
            }

            .details-card {
                p {
                    font-size: 14px;
                    margin: 4px 0;

                    strong {
                        color: #333;
                    }
                }
            }
        }

        .warning-message {
            padding: 10px;
            margin: 10px 0;
            font-size: 14px;
        }

        .button {
            padding: 7px 14px;
            margin: 5px;
            font-size: 14px;
        }

        .create-profile,
        .switch-group {
            width: 85vw;
            margin: auto;

            .create-profile-heading,
            .create-profile-subheading {
                text-align: center;
                font-size: 18px;
            }
        }

        .group-details-card {
            width: 250px !important;
            height: 250px !important;
        }

        .group-details-card-outer {
            .group-details-card-inner {
                padding: 15px 0;

                .group-details-card {
                    width: 250px !important;
                    height: 250px !important;


                    .group-details-card-a {
                        padding: 15px;

                        .group-icon {
                            font-size: 22px;
                            width: 40px;
                            height: 40px;
                            padding: 4px;
                        }

                        .group-name-details {
                            width: 90%;

                            .name {
                                padding: 12px;
                                font-size: 16px;
                            }

                            .company {
                                margin-top: 12px;
                                font-size: 14px;
                            }
                        }

                        .group-location {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .personal-information,
        .location-information {
            gap: 8px;
            margin-left: 10px;

            input {
                padding: 10px;
                font-size: 14px;
            }

            .personal-info-label-data {
                label {
                    font-size: 14px;
                }

                .personal-info-data {
                    font-size: 13px;
                }
            }
        }

        .button-group {
            flex-direction: row;
            justify-content: space-between;
            margin-right: 20px;

            .button {
                flex: 1;
                margin: 0 5px;
            }
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 575.98px) {
    .personal-information {
        .personal-info-outer {

            .personal-info-label-data {
                .personal-info-data {
                    width: 100% !important;
                }
            }
        }
    }

    .profile-manager {
        padding: 20px;

        .user-not-found-image {
            width: 60%;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .message-box {
            padding: 12px;
            margin: 12px 0;
            border-radius: 5px;
            width: 75vw;

            p {
                color: grey;
                font-size: 15px;
            }
        }

        h2 {
            font-size: 22px;
            color: #333;
        }

        .group-card {
            padding: 18px;
            margin: 12px 0;
            background-color: #f9f9f9;

            h3 {
                font-size: 20px;
            }

            .details-card {
                p {
                    font-size: 15px;
                    margin: 5px 0;

                    strong {
                        color: #333;
                    }
                }
            }
        }

        .warning-message {
            padding: 12px;
            margin: 12px 0;
            font-size: 15px;
        }

        .button {
            padding: 8px 16px;
            margin: 6px;
            font-size: 15px;
        }

        .create-profile,
        .switch-group {
            width: 75vw;
            margin: auto;

            .create-profile-heading,
            .create-profile-subheading {
                text-align: center;
                font-size: 20px;
            }
        }

        .group-details-card-outer {
            .group-details-card-inner {
                padding: 18px 0;

                .group-details-card {
                    width: 100%;
                    // height: auto;

                    .group-details-card-a {
                        padding: 15px;

                        .group-icon {
                            font-size: 24px;
                            width: 45px;
                            height: 45px;
                            padding: 5px;
                        }

                        .group-name-details {
                            width: 90%;

                            .name {
                                padding: 14px;
                                font-size: 17px;
                            }

                            .company {
                                margin-top: 14px;
                                font-size: 15px;
                            }
                        }

                        .group-location {
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        .personal-information,
        .location-information {
            gap: 10px;
            margin-left: 12px;

            input {
                padding: 12px;
                font-size: 15px;
            }

            .personal-info-label-data {
                label {
                    font-size: 15px;
                }

                .personal-info-data {
                    font-size: 14px;
                }
            }
        }

        .button-group {
            flex-direction: row;
            justify-content: space-between;
            margin-right: 25px;

            .button {
                flex: 1;
                margin: 0 8px;
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 771.2px) {
    .personal-information {
        .personal-info-outer {

            .personal-info-label-data {
                .personal-info-data {
                    width: 100% !important;
                }
            }
        }
    }

    .profile-manager {
        padding: 25px;

        .user-not-found-image {
            width: 50%;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .message-box {
            padding: 14px;
            margin: 14px 0;
            border-radius: 5px;
            width: 70vw;

            p {
                color: grey;
                font-size: 16px;
            }
        }

        h2 {
            font-size: 24px;
            color: #333;
        }

        .group-card {
            padding: 20px;
            margin: 14px 0;
            background-color: #f9f9f9;

            h3 {
                font-size: 22px;
            }

            .details-card {
                p {
                    font-size: 16px;
                    margin: 6px 0;

                    strong {
                        color: #333;
                    }
                }
            }
        }

        .warning-message {
            padding: 14px;
            margin: 14px 0;
            font-size: 16px;
        }

        .button {
            padding: 9px 18px;
            margin: 8px;
            font-size: 16px;
        }

        .create-profile,
        .switch-group {
            width: 70vw;
            margin: auto;

            .create-profile-heading,
            .create-profile-subheading {
                text-align: center;
                font-size: 22px;
            }
        }

        .group-details-card-outer {
            .group-details-card-inner {
                padding: 20px 0;

                .group-details-card {
                    width: 100%;
                    // height: auto;

                    .group-details-card-a {
                        padding: 18px;

                        .group-icon {
                            font-size: 26px;
                            width: 50px;
                            height: 50px;
                            padding: 6px;
                        }

                        .group-name-details {
                            width: 90%;

                            .name {
                                padding: 16px;
                                font-size: 18px;
                            }

                            .company {
                                margin-top: 16px;
                                font-size: 16px;
                            }
                        }

                        .group-location {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .personal-information,
        .location-information {
            gap: 12px;
            margin-left: 15px;

            input {
                padding: 12px;
                font-size: 16px;
            }

            .personal-info-label-data {
                label {
                    font-size: 16px;
                }

                .personal-info-data {
                    font-size: 15px;
                }
            }
        }

        .button-group {
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 30px;

            .button {
                flex: 1;
                margin: 0 10px;
            }
        }
    }
}

@media screen and (min-width: 772px) and (max-width: 1013px) {
    .profile-manager {
        padding: 30px;

        .user-not-found-image {
            width: 45%;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .message-box {
            padding: 16px;
            margin: 16px 0;
            border-radius: 5px;
            width: 65vw;

            p {
                color: grey;
                font-size: 17px;
            }
        }

        h2 {
            font-size: 26px;
            color: #333;
        }

        .group-card {
            padding: 22px;
            margin: 16px 0;
            background-color: #f9f9f9;

            h3 {
                font-size: 24px;
            }

            .details-card {
                p {
                    font-size: 17px;
                    margin: 6px 0;

                    strong {
                        color: #333;
                    }
                }
            }
        }

        .warning-message {
            padding: 16px;
            margin: 16px 0;
            font-size: 17px;
        }

        .button {
            padding: 10px 20px;
            margin: 8px;
            font-size: 17px;
        }

        .create-profile,
        .switch-group {
            width: 65vw;
            margin: auto;

            .create-profile-heading,
            .create-profile-subheading {
                text-align: center;
                font-size: 24px;
            }
        }

        .group-details-card-outer {
            .group-details-card-inner {
                padding: 22px 0;

                .group-details-card {
                    width: 90%;
                    // height: auto;

                    .group-details-card-a {
                        padding: 20px;

                        .group-icon {
                            font-size: 28px;
                            width: 55px;
                            height: 55px;
                            padding: 6px;
                        }

                        .group-name-details {
                            width: 90%;

                            .name {
                                padding: 18px;
                                font-size: 19px;
                            }

                            .company {
                                margin-top: 18px;
                                font-size: 17px;
                            }
                        }

                        .group-location {
                            font-size: 17px;
                        }
                    }
                }
            }
        }

        .personal-information,
        .location-information {
            gap: 14px;
            margin-left: 18px;

            input {
                padding: 14px;
                font-size: 17px;
            }

            .personal-info-label-data {
                label {
                    font-size: 17px;
                }

                .personal-info-data {
                    font-size: 16px;
                }
            }
        }

        .button-group {
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 35px;

            .button {
                flex: 1;
                margin: 0 12px;
            }
        }
    }
}


@media screen and (min-width: 1014px) and (max-width: 1200px) {
    .profile-manager {
        padding: 35px;

        .user-not-found-image {
            width: 40%;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .message-box {
            padding: 18px;
            margin: 18px 0;
            border-radius: 5px;
            width: 60vw;

            p {
                color: grey;
                font-size: 18px;
            }
        }

        h2 {
            font-size: 28px;
            color: #333;
        }

        .group-card {
            padding: 24px;
            margin: 18px 0;
            background-color: #f9f9f9;

            h3 {
                font-size: 26px;
            }

            .details-card {
                p {
                    font-size: 18px;
                    margin: 7px 0;

                    strong {
                        color: #333;
                    }
                }
            }
        }

        .warning-message {
            padding: 18px;
            margin: 18px 0;
            font-size: 18px;
        }

        .button {
            padding: 11px 22px;
            margin: 8px;
            font-size: 18px;
        }

        .create-profile,
        .switch-group {
            width: 60vw;
            margin: auto;

            .create-profile-heading,
            .create-profile-subheading {
                text-align: center;
                font-size: 26px;
            }
        }

        .group-details-card-outer {
            .group-details-card-inner {
                padding: 24px 0;

                .group-details-card {
                    width: 85%;
                    //   height: auto;

                    .group-details-card-a {
                        padding: 22px;

                        .group-icon {
                            font-size: 30px;
                            width: 60px;
                            height: 60px;
                            padding: 6px;
                        }

                        .group-name-details {
                            width: 90%;

                            .name {
                                padding: 20px;
                                font-size: 20px;
                            }

                            .company {
                                margin-top: 20px;
                                font-size: 18px;
                            }
                        }

                        .group-location {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .personal-information,
        .location-information {
            gap: 16px;
            margin-left: 20px;

            input {
                padding: 14px;
                font-size: 18px;
            }

            .personal-info-label-data {
                label {
                    font-size: 18px;
                }

                .personal-info-data {
                    font-size: 17px;
                }
            }
        }

        .button-group {
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 40px;

            .button {
                flex: 1;
                margin: 0 14px;
            }
        }
    }
}