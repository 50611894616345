/* Carousel.css */
.carousel-wrapper {
margin: auto;
width: 100%;
.mobile-carousel-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust the height to match the phone size */
  display: flex;
  justify-content: center;
  align-items: center;

.phone-frame {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  height: 100%;
}

.phone-frame img {
  // width: 300px; /* Adjust to the actual width of the phone image */
  height: 100%;
  margin-left: 6px;
}

.slider-wrapper {
  width: 100vw; /* Match the width of the phone frame */
  position: relative;
  z-index: 1;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0px 20px;
}
.active-slide {
  // transform: scale(1.1);
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

}
}