/* General Styling */
.page-container {
  font-family: "Roboto", sans-serif;
  margin-top: 103px;
  padding: 0;
}

/* Hero Section */
.hero-section {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 50px;
  position: relative;
}

.hero-content {
  max-width: 30%;
  z-index: 2;
  margin: 60px;
  text-align: left;
  color: white;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

.hero-buttons {
  display: flex;
  gap: 20px;
}

.primary-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.secondary-btn {
  background-color: transparent;
  color: #007bff;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
}

.hero-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fcfcfc;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

.hero-stats {
  display: flex;
  gap: 20px;
  z-index: 2;
  div {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 5px;
  }
}

/* Services Section */
.services-section {
  text-align: center;
  padding: 50px 8%;
  h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;
    .card-image-outer {
      width: 100%;
      height: 200px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .service-box {
      background-color: #fff;
      border-radius: 10px;
      padding: 30px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      text-align: center;
      text-align: left;
      transition: all 0.3s ease;
      h3 {
        margin-bottom: 10px;
      }
      ul {
        color: grey;
        padding: 0;
        text-align: left;
        list-style-type: circle;
        font-size: 14px;
        margin-bottom: 10px;
      }
      li {
        margin-bottom: 10px;
      }
    }
    .service-box:hover {
      transform: scale(1.05);
    }
  }
}

/* About Section */
.about-section {
  text-align: center;
  background-color: #f8f8f8;
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .about-container {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  .about-image {
    img {
      max-width: 100%;
      border-radius: 10px;
    }
  }
  .about-content {
    text-align: left;
    max-width: 400px;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
      border-left: 5px #44b1e1 solid;
      padding: 0px 10px;
    }
  }
}

/* Responsive */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-content,
  .hero-image {
    max-width: 100%;
  }

  .hero-stats {
    flex-direction: column;
  }

  .services-container {
    flex-direction: column;
  }

  .about-container {
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .page-container {
    font-family: "Roboto", sans-serif;
    margin-top: 103px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
