.saas-offerings {
    padding: 50px 100px;
    text-align: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7385547969) 0%, rgb(242, 249, 255) 100%);
  .sass-offering-sub-heading {
    background: #E6F5FF;
    color:#32A0EF;
    width: min-content;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 15px;
    margin: auto;
    font-size: 16px;
}
    @media (max-width: 768px) {
        padding: 50px 10px;
      }
    &__heading {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: #333;
      font-weight: 700;
    }
  
    &__subheading {
      font-size: 1.2rem;
      color: #666666;
      margin-bottom: 3rem;
    }
  
    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  
    .saas-offering-item {
      border-radius: 10px;
      overflow: hidden;
      transition: transform 0.1s;
  
    //   &:hover {
    //     transform: translateY(-10px);
    //     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    //     background-color: #f9f9f9;
    //   }
  
      &__image {
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
  
      &__content {
        padding: 1.5rem;
        text-align: left;
  
        h3 {
          font-size: 1.5rem;
          color: #333;
          margin-bottom: 1rem;
        }
  
        p {
          font-size: 1rem;
          color: #555;
          line-height: 1.6;
          white-space: pre-wrap;
        }
      }
    }
  }
  