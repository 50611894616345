$primary-color: #ff4c4c;
$secondary-color: #333;
$background-color: #f9f9f9;
$text-color: #555;
$border-color: #ddd;
$input-border-color: #ccc;
$hover-color: #ff6666;
$success-color: #4caf50;
$error-color: $primary-color;

.page-wrapper {
  width: 100%;
  .confirming-box {
    background-color: white;
    max-width: 400px;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 20px;

    .delete-account-container {
      max-width: 600px;
      border-radius: 10px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .delete-icon {
        width: 48px;
        height: 48px;
        align-items: flex-start;
        display: flex;
      }
      .heading {
        font-size: 18px;
        text-align: left;
        color: rgba(24, 29, 39, 1);
        font-weight: 600;
      }
      .delete-text {
        font-size: 14px;
        text-align: left;
        color: rgba(83, 88, 98, 1);
        font-weight: 500;
      }
      .username-input {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        label {
          background-color: rgb(255, 255, 255);
          font-size: 13px;
          text-align: center;
          color: rgba(102, 102, 102, 1);
          font-weight: 600;
          height: 8px;
          position: relative;
          width: 65%;
          z-index: 1000;
        }
        input {
          width: 90%;
          border-radius: 10px;
          padding: 15px;
          margin: auto;
          border: 1.5px solid rgba(204, 204, 204, 1);
        }
        input:focus {
          outline: none;
        }
      }

      .delete-btn {
        background-color: #f73535;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        padding: 10px;
        width: 100%;
        margin-top: 15px;
        border-radius: 10px;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
          border: 1px solid #ccc;
        }
      }
    }
  }
}

.error-message {
  color: $error-color;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

// Small devices (portrait phones, less than 576px)
@media (min-width: 320px) and (max-width: 575.98px) {
  .page-wrapper {
    margin: auto;
    width: 90%;
    .confirming-box {
      max-width: 95%;
      margin-top: 90px;
      margin-bottom: 40px;
      padding: 10px;
      .delete-account-container {
        margin: 0px auto;
        padding: 30px;
        border-radius: 10px;
        text-align: center;
      }

      .profile-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        .profile-image {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          border: 3px solid #ddd;
          margin-bottom: 20px;
        }

        .user-info {
          h2 {
            font-size: 1.8em;
            margin: 0;
          }

          p {
            font-size: 1.1em;
            color: #555;
          }
        }
      }

      .warning-section {
        background-color: #fff3cd;
        border: 1px solid #ffeeba;
        padding: 20px;
        border-radius: 8px;
        color: #856404;
        margin-bottom: 20px;

        p {
          font-size: 1.1em;
        }
      }

      .actions {
        display: flex;
        justify-content: center;
        gap: 20px;

        .delete-button {
          padding: 12px 20px;
          background-color: #ff4c4c;
          color: #fff;
          border: none;
          border-radius: 5px;
          font-size: 1.1em;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #ff3333;
          }
        }

        .cancel-button {
          padding: 12px 20px;
          background-color: #ccc;
          color: #333;
          border: none;
          border-radius: 5px;
          font-size: 1.1em;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #b3b3b3;
          }
        }
      }
    }
  }
  .page-wrapper
    .confirming-box
    .delete-account-container
    .username-input
    label {
    background-color: rgb(255, 255, 255);
    font-size: 12px;
    text-align: center;
    color: rgb(102, 102, 102);
    font-weight: 600;
    height: 25px;
    position: relative;
    width: 100%;
    z-index: 1000;
  }
  .page-wrapper
    .confirming-box
    .delete-account-container
    .username-input
    input {
    width: 85%;
    border-radius: 10px;
    padding: 15px;
    margin: auto;
    border: 1.5px solid rgb(204, 204, 204);
  }
  .page-wrapper .confirming-box .delete-account-container .delete-btn {
    background-color: #f73535;
    font-weight: 600;
    color: rgb(255, 255, 255);
    padding: 15px;
    border: none;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    margin-top: 25px;
    font-size: 16px;
  }
}

// Medium devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .page-wrapper {
    .confirming-box {
      max-width: 95%;
      padding: 10px;
      .main-modalsection {
        margin: 15px;
        padding: 10px;
        .modalsection-heading {
          .datasection-main {
            .data-profile-inner-data {
              .partition-line-vertical {
                width: 2px;
                background: rgb(204, 204, 204);
                margin-top: 30px;
                height: 20px;
              }
              .value-name {
                margin-left: 10px;
                .first-initial-box {
                  width: 30px;
                  height: 30px;
                  font-size: 20px;
                }
                .first_name-data,
                .last_name-data {
                  font-size: 18px;
                  margin-top: 2px;
                }
              }
            }
            .data-profile-inner-data {
              gap: 10px;
              .data-designation,
              .data-phone_number,
              .data-email {
                flex-direction: column;
                .icon-name {
                  font-size: 10px;
                }
              }
            }
          }
          .profiles-outer {
            flex-direction: column;
            gap: 10px;
            align-content: center;
            .profile-card {
              width: 40%;
              .profile-info {
                .profile-name-designation {
                  .full-name {
                    font-size: 14px;
                  }
                  .profile-designation {
                    font-size: 10px;
                  }
                }
              }
              .profile-contact {
                .phone-email-main {
                  width: 90%;
                }
              }
            }
          }
          .main-document-section {
            flex-direction: column;
            height: auto;
            width: 40%;
            margin: auto;
            .documents-outer {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// Large devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-wrapper {
    margin-top: 100px;
    .confirming-box {
      max-width: 85%;
      padding: 20px;
      .main-modalsection {
        margin: 25px;
        padding: 20px;
        .modalsection-heading {
          .datasection-main {
            .data-profile-inner {
              .value-name {
                margin-left: 20px;
                .first-initial-box {
                  width: 30px;
                  height: 30px;
                  font-size: 20px;
                }
                .first_name-data,
                .last_name-data {
                  font-size: 16px;
                }
              }
            }
            .data-profile-inner-data {
              flex-direction: row;
              gap: 20px;
              .data-designation,
              .data-phone_number,
              .data-email {
                .icon-name {
                  font-size: 12px;
                }
              }
            }
          }
          .profiles-outer {
            flex-direction: row;
            gap: 15px;
            .profile-card {
              width: 30%;
            }
          }
          .main-document-section {
            flex-direction: row;
            gap: 10px;
            .documents-outer {
              width: 22%;
              .document-profile-inner {
                .file-details {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Extra large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-wrapper {
    .confirming-box {
      max-width: 80%;
      padding: 25px;
      .main-modalsection {
        margin: 30px;
        padding: 25px;
        .modalsection-heading {
          .datasection-main {
            .data-profile-inner {
              .value-name {
                margin-left: 20px;
                .first_name-data,
                .last_name-data {
                  font-size: 16px;
                }
              }
            }
            .data-profile-inner-data {
              flex-direction: row;
              gap: 20px;
              .data-designation,
              .data-phone_number,
              .data-email {
                .icon-name {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .profiles-outer {
        flex-direction: row;
        gap: 15px;
        .profile-card {
          width: 21.55%;
        }
      }
      .main-document-section {
        flex-direction: row;
        gap: 10px;
        .documents-outer {
          width: 18%;
        }
      }
    }
  }
}

// Extra large screens (1200px and up)
@media (min-width: 1400px) and (max-width: 1600px) {
  .page-wrapper {
    margin-top: 104px;
    .confirming-box {
      max-width: 75%;
      padding: 30px;
    }
    .profiles-outer {
      gap: 20px;
      .profile-card {
        width: 21%;
      }
    }
    .main-document-section {
      gap: 15px;
      .documents-outer {
        width: 18%;
      }
    }
  }
}
