.achievements {
    text-align: center;
    padding: 50px 100px;
    @media (max-width: 768px) {
        padding: 50px 10px;
      }
  
    &__grid {
        .bg-cover {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      padding: 2rem 0;
      background: #F0F1FF;
  
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (max-width: 480px) {
        grid-template-columns: 1fr;
        display: none;
      }
    }
    .milestone-sub-heading {
        background: #E6F5FF;
        font-size: 18px;
        color:#32A0EF;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
    }
    .milestone-sub-heading-2 {
        font-size: 18px;
        width: 60%;
        margin: 20px auto;
    }
  
    .achievement {
    //   background-color: #f9f9f9;
      padding: 2rem;
    //   border-radius: 8px;
    //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
      h3 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 1rem;
      }
    }
  }
  