.update-password-layout {
  margin-top: 100px;

  .main-update-password {
    background-color: #ffffff;
    margin: auto;
    border-radius: 15px;
    border: 1px;
    text-align: center;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 30px;
    width: 35%;
    .header {
      display: flex;
      flex-direction: column;
      .logo {
        width: 100px;
      }
    }
    .lock-icon {
      width: 100%;
      height: 100px;
    }
    .container {
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: #fff;
      h2 {
        margin-bottom: 20px;
        color: rgba(0, 171, 227, 1);
        font-size: 26px;
        font-weight: 700;
        border-radius: 5px;
        padding: 10px;
      }
      .form-corporate-newpassword {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .input-wrapper {
          position: relative;
          width: 85%;
          padding: 12px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 10px;
          outline: none;
          .eye-icon {
            font-size: 13px;
            color: grey;
          }

          .email-input {
            width: 90%;
            border: none;
            font-size: 14px;
            color: gray;
            font-weight: 600;
            outline: none;
          }
          .password-input {
            font-size: 14px;
            outline: none;
            border: none;
            color: black;
            font-weight: 400;
            width: 90%;
          }
          .toggle-password {
            border: none;
            outline: none;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            font-size: 18px;
            color: #333;
          }
        }
      }
      a {
        text-decoration: none;
      }
      .main-submit-btn {
        width: 70%;
      
        button {
          display: flex;
          justify-content: center;
          padding: 10px;
          border-radius: 10px;
          border: 1px solid rgb(0, 171, 227);
          background-color: rgb(0, 171, 227);
          font-size: 16px;
          width: 100%;
          margin: auto;
          color: white;
          box-shadow: 0 8px 9px rgba(5, 80, 114, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
          transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
      
          &:hover:not(:disabled) {
            background-color: rgb(0, 150, 200);
            transform: translateY(-2px);
            cursor: pointer;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
          }
        }
      
        button:disabled {
          font-weight: 700;
          color: #fff;
          background-color: rgb(129, 128, 128);
          border: none;
          cursor: not-allowed;
        }
      }
      
    }
  }

  .heading {
    color: #46586b;
    font-size: 24px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 25px;
  }

  /* New styles for error states */
  .input-wrappererror {
    position: relative;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid red;
    border-radius: 10px;
    outline: none;
    .eye-icon {
      font-size: 13px;
      color: grey;
    }
    .email-input {
      max-width: 300px;
      border: none;
      font-size: 14px;
      color: gray;
      font-weight: 600;
    }
    .password-input {
      font-size: 14px;
      outline: none;
      border: none;
      color: #333333;
      font-weight: 400;
      width: 90%;
    }
    .toggle-password {
      border: none;
      outline: none;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 18px;
      color: #333;
    }
  }
  .input-wrapper.error {
    border-color: red;
  }

  .error-message {
    color: red;
    font-size: 12px;
    padding: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: center;
  }

  .password-input.error {
    border-color: red;
  }
}

.visit-website {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #f0f8ff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

.visit-website-content {
  text-align: center;
  max-width: 600px;
}

.visit-website-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.visit-website-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #3c93fb;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.visit-website-button:hover {
  background-color: #2a6bbf;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); 
}

.visit-website-button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgba(60, 147, 251, 0.4);
}

.visit-website-button:active {
  background-color: #1e4e8c;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

.button-icon {
  font-size: 20px;
  line-height: 1;
}

@media (max-width: 768px) {
  .visit-website-text {
      font-size: 16px; 
  }

  .visit-website-button {
      font-size: 14px;
      padding: 10px 20px;
  }
}

/* Media Queries for different screen sizes */

@media screen and (min-width: 320px) and (max-width: 360px) {
  .update-password-layout {
    .main-update-password {
      width: 90%;
      margin-top: 20px;
      .heading {
        font-size: 20px;
      }
      .container h2 {
        font-size: 22px;
      }
    }
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .update-password-layout {
    .main-update-password {
      width: 80%;
      margin-top: 30px;
    }
    .heading {
      font-size: 22px;
    }
    .container h2 {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 575.98px) {
  .update-password-layout {
    .main-update-password {
      width: 70%;
      margin-top: 40px;
      .heading {
        font-size: 23px;
      }
      .container h2 {
        font-size: 25px;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 771.2px) {
  .update-password-layout {
    .main-update-password {
      width: 60%;
      margin-top: 50px;
      .heading {
        font-size: 24px;
      }
      .container h2 {
        font-size: 26px;
      }
    }
  }
}
@media screen and (min-width: 772px) and (max-width: 1013.2px) {
  .update-password-layout {
    .main-update-password {
      width: 60%;
      margin-top: 50px;
      .heading {
        font-size: 24px;
      }
      .container h2 {
        font-size: 26px;
      }
    }
  }
}
@media screen and (min-width: 1014px) and (max-width: 1200px) {
  .update-password-layout {
    .main-update-password {
      width: 50%;
      margin-top: 60px;
      .heading {
        font-size: 26px;
      }
      .container h2 {
        font-size: 28px;
      }
    }
  }
}
