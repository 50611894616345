/* VerticalCarousel.css */
.carousel-container {
    position: relative;
    // width: 300px;
    height: 400px; /* 4 items * 100px/item = 400px */
    overflow: hidden;
    // border: 1px solid #ddd;
  }
  
  .carousel-viewport {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .carousel-content {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease;
  }
  
  .carousel-item {
    // height: 100px; /* Adjust based on your design */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #f5f5f5;
    margin: 5px 0;
    // border: 1px solid #ddd;
  }
  
  .carousel-control {
    position: absolute;
    background-color: #333;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-control.prev {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carousel-control.next {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  