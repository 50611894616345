.go-home-button {
    background-color: #3c93fb; /* Blue color */
    color: #fff; /* White text */
    border: none; /* Remove default border */
    border-radius: 8px; /* Rounded corners */
    padding: 12px 24px; /* Spacing */
    font-size: 16px; /* Text size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: all 0.3s ease; /* Smooth hover effect */
  }
  
  .go-home-button:hover {
    background-color: #2d78cb; /* Darker blue on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Increased shadow on hover */
    transform: translateY(-2px); /* Lift effect on hover */
  }
  
  .go-home-button:active {
    background-color: #1c5fa1; /* Even darker blue on active */
    transform: translateY(0); /* Reset lift effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduce shadow on click */
  }
  