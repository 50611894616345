.product-home {
  .section-1 {
    width: 100%;
    position: relative;

    .background-container-product-info {
      display: flex;
      z-index: -1;

      img {
        width: 100%;
      }
    }

    .section-1-inner {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      .section-1-2 {
        margin-left: 80px;

        .cta-button {
          border: solid 1px #32a0ef;
          border-radius: 50px;
          padding: 5px 10px;
          background: white;
          color: rgb(87, 87, 87);
          display: flex;
          align-items: center;
        }

        h1 {
          margin: 20px auto;
          font-size: 64px;
          color: rgba(50, 160, 239, 1);
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        }

        .sub-heading {
          margin-bottom: 20px;
          color: #333333;
          font-weight: 400;
        }

        .rating-outer {
          display: flex;
          align-items: center;

          .rating-inner {
            margin-right: 10px;

            img {
              width: 6.5vw;
            }
          }

          .rating-text {
            font-size: 14px;
            font-weight: 700;
            color: #333333;
          }

          .rating {
            font-size: 13px;

            .star {
              color: #ffb636;
            }

            .rating-text-inner {
              color: grey;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .section-2 {
    padding: 80px;

    .product-info-box {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .left-div,
      .right-div {
        flex: 45%;
        padding: 10px;
      }

      .left-div {
        .left-box {
          margin-bottom: 20px;

          h2 {
            color: rgb(51, 51, 51);
            font-size: 36px;
            font-weight: 700;
            text-align: left;
          }

          p {
            color: rgb(51, 51, 51);
            font-size: 17px;
            width: 100%;
            text-align: left;
          }
        }

        .left-sub-box {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .tick-text {
            display: flex;
            align-items: flex-start; // Align tick and text at the top
            gap: 15px;

            .frame-tick-icon {
              background-color: rgba(50, 160, 239, 0.1);
              border-radius: 50px;
              width: 20px;
              height: 20px;
              margin-top: 10px;

              .tick-icon {
                color: rgb(50, 160, 239);
                margin-left: 8px;
                margin-bottom: 10px;
              }
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 85%;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 22px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .right-div {
        margin-left: 10%;

        .image-grid {
          display: grid;
          grid-template-columns: repeat(2, 0fr);
          gap: 10px;

          .image-box {
            background: var(--border-placeholder, rgba(204, 204, 204, 1));
            opacity: 1;
            border-radius: 10px;
          }

          .image-box-large-left {
            width: 11.5vw;
            height: 14.6vw;
            margin-bottom: 0;
            margin-top: auto;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .image-box-large-right {
            width: 11.5vw;
            height: 14.6vw;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .image-box-small-left {
            width: 11.5vw;
            height: 11.5vw;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .image-box-small-right {
            width: 11.5vw;
            height: 11.5vw;
            margin-top: 45px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  .section-3 {
    margin: 80px 80px 10px 80px;
    .upper-div {
      .upper-div-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
      }

      .left-box-section-3 {
        display: flex;
        border-bottom: 4px solid rgba(50, 160, 239, 1);
        padding-bottom: 20px;

        .left-box-heading {
          background: #e6f5ff;
          font-size: 16px;
          color: #32a0ef;
          width: min-content;
          white-space: nowrap;
          padding: 5px 10px;
          border-radius: 15px;
          margin: auto;
        }

        .left-box-section-3-heading {
          color: rgb(51, 51, 51);
          font-size: 25px;
          font-weight: 700;
          text-align: left;
          margin-top: 30px;
        }

        .left-box-section-3-sub-heading {
          color: rgb(51, 51, 51);
          font-size: 22px;
          width: 100%;
          margin-top: 30px;
          text-align: left;
        }
      }
    }

    .lower-div {
      display: flex;
      margin: 40px auto;

      .left-div-lower {
        width: 33%;
        display: flex;
        .iphone {
          img {
            width: 94%;
            margin-top: 30px;
          }
        }

        .left-side-star {
          img {
            width: 20px;
            height: 140%;
          }
        }

        .right-side-star {
          img {
            width: 22px;
          }
        }
      }

      .right-div-lower {
        width: 90%;
        margin-top: 35px;
        display: flex;

        .left-sub-box {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .tick-text {
            display: flex;
            margin-bottom: 30px;
            align-items: flex-start; // Align tick and text at the top
            gap: 15px;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 60px;
              font-family: monospace;
              margin-top: -25px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 85%;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .right-sub-box {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-top: 40px;

          .tick-text {
            display: flex;
            margin-bottom: 30px;
            align-items: flex-start; // Align tick and text at the top
            gap: 15px;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 60px;
              font-family: monospace;
              margin-top: -25px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 85%;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .section-4 {
    display: flex;
    margin: 0px 0px 0px 0px;

    .section-4-inner-div {
      margin: 80px;
      background: white;
      border-radius: 0px 0px 10px 10px;
      width: 100%;
      padding: 0px 45px;
      height: 100%;

      .sub-right-div {
        margin-top: 40px;

        .upper-div {
          .left-box-section-3 {
            width: 70%;
            margin: auto;
            line-height: 20px;
            text-align: center;

            .left-box-heading {
              background: #e6f5ff;
              font-size: 16px;
              color: #32a0ef;
              width: min-content;
              white-space: nowrap;
              padding: 5px 10px;
              border-radius: 15px;
              margin: auto;
            }

            h2 {
              color: rgb(51, 51, 51);
              font-size: 36px;
              font-weight: 700;
              margin-top: 30px;

              .company-name {
                color: rgba(50, 160, 239, 1);
                font-size: 36px;
                font-weight: 700;
              }
            }

            p {
              color: rgb(51, 51, 51);
              font-size: 17px;
              width: 100%;
              margin-top: 30px;
            }
          }
        }

        .lower-div {
          display: grid;
          justify-content: center;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          align-items: baseline;

          .tick-text {
            display: inline-grid;
            margin-bottom: 30px;
            align-items: flex-start;
            gap: 5px;
            padding: 20px;
            border: 1px solid #f1f1f1;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
            border-radius: 20px;

            .frame-numb {
              color: #32a0ef;
              font-size: 30px;
              margin-top: 6px;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              text-align: center;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 18px;
                color: rgba(51, 51, 51, 1);
                padding: 10px;
                border-bottom: 2px solid #32a0ef;
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }

  .section-5 {
    .upper-part {
      margin: auto;
      padding-top: 80px;

      .upper-part-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
      }

      .heading-firstpart {
        background: linear-gradient(90deg, #32a0ef 0%, #1d5c89 48.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        font-weight: 800;
        text-align: center;
      }

      .heading-secondpart {
        font-size: 34px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }
    }

    .middle-part {
      margin: 50px 100px 0px 80px;
      display: flex;
      justify-content: space-between;

      .first-div {
        width: 23%;

        .tick-text-1,
        .tick-text-2,
        .tick-text-3 {
          background: rgb(255 255 255 / 34%);
          display: flex;
          flex-direction: column;
          margin-bottom: 35px;
          align-items: flex-start;
          padding: 20px;
          border: 1px solid #32a0ef;
          border-radius: 15px;
          backdrop-filter: blur(2px);

          .frame-numb {
            width: 25px;
            margin: 0px 0px 20px 0px;
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            font-weight: 800;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 100%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 16px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .second-div {
        width: 50%;
        margin-top: -23px;

        img {
          width: 100%;
        }
      }

      .third-div {
        width: 23%;

        .tick-text-9,
        .tick-text-8,
        .tick-text-7 {
          background: rgb(255 255 255 / 34%);
          display: flex;
          flex-direction: column;
          margin-bottom: 35px;
          padding: 20px;
          border: 1px solid #32a0ef;
          align-items: flex-start;
          border-radius: 15px;
          backdrop-filter: blur(2px);

          .frame-numb {
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            margin: 0px 0px 20px 0px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            font-weight: 800;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 100%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 16px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .lower-part {
      display: flex;
      justify-content: space-between;
      margin: 0px 80px;
      .tick-text-4,
      .tick-text-5,
      .tick-text-6 {
        background: rgb(255 255 255 / 34%);
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        align-items: center;
        width: 21%;
        padding: 20px;
        border: 1px solid #32a0ef;
        align-items: flex-start;
        border-radius: 15px;
        backdrop-filter: blur(2px);

        .frame-numb {
          background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
          margin: 0px 0px 20px 0px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 40px;
          font-family: monospace;
          font-weight: 800;
          margin-right: 206px;
        }

        .main-sub-heading-third-div {
          display: flex;
          flex-direction: column;
          text-align: left;
          gap: 5px;
          width: 71%;
          margin-top: -10px;

          .sub-heading-third-div {
            font-weight: bold;
            font-size: 16px;
            color: rgba(51, 51, 51, 1);
          }

          .sub-content-third-div {
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .section-6 {
    .section-6-sub-heading {
      background: #e6f5ff;
      font-size: 16px;
      color: #32a0ef;
      width: min-content;
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 15px;
      margin: auto;
    }

    .upper-part {
      display: flex;
      justify-content: center;
      margin: auto;
      padding-top: 40px;

      .heading-firstpart {
        color: rgb(50, 160, 239);
        font-size: 36px;
        font-weight: 700;
        text-align: center;
      }

      .heading-secondpart {
        font-size: 34px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }

      .star-section6 {
        img {
          height: 30px;
          margin-top: -10px;
        }
      }
    }

    .lower-part {
      margin-top: 50px;
      display: flex;
      gap: 50px;

      .carousel-wrapper {
        .section6-image-1 {
          img {
            width: 18%;
          }
        }

        .section6-image-2 {
          img {
            width: 18%;
          }
        }

        .section6-image-3 {
          img {
            width: 18%;
          }
        }

        .section6-image-4 {
          img {
            width: 18%;
          }
        }

        .section6-image-5 {
          img {
            width: 18%;
          }
        }

        .section6-image-6 {
          img {
            width: 18%;
          }
        }
      }
    }
  }

  .section-7 {
    margin-top: 50px;

    .section-7-sub-heading {
      background: #e6f5ff;
      font-size: 16px;
      color: #32a0ef;
      width: min-content;
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 15px;
      margin: auto;
    }

    .upper-part {
      display: flex;
      justify-content: center;
      margin: auto;

      .heading-firstpart {
        color: rgb(50, 160, 239);
        font-size: 36px;
        font-weight: 700;
        text-align: center;
      }

      .heading-secondpart {
        font-size: 34px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }
    }

    .lower-part {
      width: 100%;
      height: 350px;
      margin-bottom: 100px;
      background: var(--antrocorp-bg, rgba(234, 246, 255, 1));
      margin-top: 50px;

      .left-div-lower {
        display: flex;
        margin-top: 100px;
        margin-left: 80px;

        .iphone {
          width: 70%;

          img {
            height: 450px;
            margin-top: -46px;
          }
        }

        .upper-part {
          gap: 10px;
          margin-top: 15px;
          display: flex;

          .tick-text-1,
          .tick-text-2,
          .tick-text-3 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .frame-numb {
              margin: 5px auto;

              img {
                width: 40px;
                height: 40px;
              }
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: center;
              gap: 5px;
              width: 100%;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

// add responsiveness to this code for different sizes which are given

@media (max-width: 575.98px) {
  .product-home {
    width: 100%;

    .section-1 {
      height: 150px;
      width: 100%;
      .background-container-product-info {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .section-1-inner {
        .section-1-2 {
          text-align: left;
          margin-top: 22px;
          margin-left: 20px;

          .cta-button {
            font-size: 11px;
            padding: 0px 5px;
          }

          h1 {
            margin: 5px;
            font-size: 25px;
            color: rgb(50, 160, 239);
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
          }

          .sub-heading {
            font-weight: 400;
            width: 50%;
            font-size: 8px;
            margin-bottom: 5px;
            color: #333333;
          }
        }
      }
    }

    .section-2 {
      padding: 30px !important;

      .product-info-box {
        display: block !important;
        justify-content: space-between;
        padding: 20px;

        .left-div,
        .right-div {
          display: flex;
          margin-top: 20px;
          gap: 0px;
          flex-direction: column;
        }

        .left-div {
          .left-box {
            margin: auto;

            h2 {
              font-size: 20px;
              font-weight: 700;
              text-align: left;
            }

            p {
              color: rgb(51, 51, 51);
              font-size: 18px;
              width: 100%;
              text-align: left;
              margin-top: 2px;
            }
          }

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              align-items: flex-start;
              gap: 15px;

              .frame-tick-icon {
                background-color: rgba(50, 160, 239, 0.1);
                border-radius: 50px;
                width: 20px;
                height: 20px;
                margin-top: 10px;

                .tick-icon {
                  color: rgb(50, 160, 239);
                  margin-left: 8px;
                  margin-bottom: 10px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 20px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .right-div {
          display: flex;
          width: 100%;
          margin: auto;
          justify-content: center;
          align-items: center;

          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 0fr);
            gap: 10px;

            .image-box {
              background: var(--border-placeholder, rgba(204, 204, 204, 1));
              opacity: 1;
              border-radius: 10px;
            }

            .image-box-large-left {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-large-right {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-small-left {
              height: 20.5vw;
              width: 20.5vw;
            }

            .image-box-small-right {
              height: 20.5vw;
              width: 20.5vw;
              margin-top: 45px;
            }
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      margin: auto;
      margin-top: 10px;

      .upper-div {
        .left-box-section-3 {
          display: flex;
          flex-direction: column;
          padding-bottom: 0px;
          width: 80%;
          justify-content: center;
          border-bottom: 4px solid rgba(50, 160, 239, 1);
          margin: auto;

          .left-box-heading {
            background: #e6f5ff;
            font-size: 16px;
            color: #32a0ef;
            width: min-content;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 15px;
            margin: auto;
          }

          .left-box-section-3-heading {
            color: rgb(51, 51, 51);
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            margin-top: 60px;
          }

          .left-box-section-3-sub-heading {
            color: rgb(51, 51, 51);
            font-size: 16px;
            margin: auto;
            margin-top: 10px;
            text-align: left;
            margin-bottom: 20px;
          }
        }
      }

      .lower-div {
        gap: 40px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left-div-lower {
          width: 50%;
          display: flex;
          margin: auto;
          margin-top: 20px;

          .iphone {
            img {
              width: 94%;
            }
          }

          .left-side-star {
            img {
              width: 20px;
              height: 140%;
            }
          }

          .right-side-star {
            img {
              width: 20px;
            }
          }
        }

        .right-div-lower {
          width: 70%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }

          .right-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 10px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .section-4 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;

      .section-4-inner-div {
        padding: auto;
        margin: auto;
      }

      .section-4-inner-div {
        background: white;
        padding: 0px;
        margin: 0px;
        border-radius: 0px 0px 10px 10px;
        height: auto;

        .left-div {
          width: 100%;
          height: 250px;
          background-color: rgba(204, 204, 204, 1);
        }

        .right-div {
          background: white;
          border-radius: 0px 0px 10px 10px;
          width: 90%;
          margin: auto;
          border-top: 4px solid rgb(50, 160, 239);
          padding: 0px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .sub-right-div {
          margin-top: 40px;
          padding: 15px;

          .upper-div {
            .left-box-section-3 {
              width: 100%;
              margin: auto;
              line-height: 20px;

              .left-box-heading {
                background: #e6f5ff;
                font-size: 16px;
                color: #32a0ef;
                width: min-content;
                white-space: nowrap;
                padding: 5px 10px;
                border-radius: 15px;
                margin: auto;
              }

              h2 {
                color: rgb(51, 51, 51);
                font-size: 20px;
                line-height: 30px;
                font-weight: 700;
                text-align: center;
                margin-top: 0px;
                line-height: 30px;

                .company-name {
                  color: rgba(50, 160, 239, 1);
                  font-size: 20px;
                  font-weight: 700;
                }
              }

              p {
                color: rgb(51, 51, 51);
                font-size: 16px;
                width: 100%;
                margin-top: 10px;
                text-align: left;
              }
            }
          }

          .lower-div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .left-div-lower {
              width: 30%;
              display: flex;
              margin-top: 40px;
              margin-left: 80px;
            }

            .right-div-lower {
              width: 100%;
              flex-direction: column;
              margin-top: 20px;
              display: flex;
              gap: 25px;

              .left-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start; // Align tick and text at the top
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 25px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 1px;
                      font-weight: 400;
                      width: 100%;
                    }
                  }
                }
              }

              .right-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;
                margin-top: -20px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start;
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 20px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 15px;
                      width: 100%;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .section-5 {
      width: 100%;
      .upper-part {
        margin: auto;
        padding-top: 20px;
        width: 90%;

        .heading-firstpart {
          background: linear-gradient(90deg, #32a0ef 0%, #1d5c89 48.07%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 20px;
          font-weight: 800;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 20px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .middle-part {
        margin-top: 20px;
        margin: 20px 10px 20px 10px;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .first-div {
          width: 100%;

          .tick-text-1,
          .tick-text-2,
          .tick-text-3 {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }

        .second-div {
          width: 100%;
          margin: auto;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .third-div {
          width: 100%;

          .tick-text-9,
          .tick-text-8,
          .tick-text-7 {
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .lower-part {
        margin-top: 0px;
        margin: 20px 10px 20px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .tick-text-5 {
          margin-top: 0px;
        }

        .tick-text-4,
        .tick-text-5,
        .tick-text-6 {
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;
          align-items: flex-start;
          width: 86%;

          .frame-numb {
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            margin-top: 0px;
            font-weight: 800;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 100%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 20px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .section-6 {
      width: 100%;

      .upper-part {
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 40px;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }

        .star-section6 {
          img {
            height: 30px;
            margin-top: -10px;
          }
        }
      }

      .lower-part {
        margin-top: 50px;
        display: flex;
        gap: 50px;

        .carousel-wrapper {
          .section6-image-1 {
            img {
              width: 100%;
            }
          }

          .section6-image-2 {
            img {
              width: 100%;
            }
          }

          .section6-image-3 {
            img {
              width: 100%;
            }
          }

          .section6-image-4 {
            img {
              width: 100%;
            }
          }

          .section6-image-5 {
            img {
              width: 100%;
            }
          }

          .section6-image-6 {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .section-7 {
      width: 100%;
      margin-top: 50px;
      .section-7-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
      }
      .upper-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 85%;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .lower-part {
        width: 100%;
        height: auto;
        margin-bottom: 100px;
        background: var(--antrocorp-bg, rgba(234, 246, 255, 1));
        margin-top: 50px;

        .left-div-lower {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 100px;
          padding-bottom: 30px;
          margin-left: 0px;

          .iphone {
            width: 50%;

            img {
              width: 100%;
              height: 350px;
              margin: auto;
              margin-top: 20px;
              margin-left: 0px;
            }
          }

          .upper-part {
            gap: 10px;
            margin-top: 15px;
            display: flex;

            .tick-text-1,
            .tick-text-2,
            .tick-text-3 {
              display: flex;
              flex-direction: column;
              align-items: center;

              .frame-numb {
                margin: auto;

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 5px;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 18px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 642.98px) {
  .product-home {
    width: 100%;

    .section-1 {
      height: 150px;
      width: 100%;
      .background-container-product-info {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .section-1-inner {
        .section-1-2 {
          margin-top: 100px;

          h1 {
            font-size: 35px;
          }

          .sub-heading {
            font-weight: 400;
            width: 50%;
            color: #333333;
          }
        }
      }
    }

    .section-2 {
      padding: 30px !important;

      .product-info-box {
        display: block !important;
        justify-content: space-between;
        padding: 20px;

        .left-div,
        .right-div {
          display: flex;
          margin-top: 20px;
          gap: 0px;
          flex-direction: column;
        }

        .left-div {
          .left-box {
            margin: auto;

            h2 {
              font-size: 20px;
              font-weight: 700;
              text-align: left;
            }

            p {
              color: rgb(51, 51, 51);
              font-size: 18px;
              width: 100%;
              text-align: left;
              margin-top: 2px;
            }
          }

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-tick-icon {
                background-color: rgba(50, 160, 239, 0.1);
                border-radius: 50px;
                width: 20px;
                height: 20px;
                margin-top: 10px;

                .tick-icon {
                  color: rgb(50, 160, 239);
                  margin-left: 8px;
                  margin-bottom: 10px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 20px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .right-div {
          display: flex;
          width: 100%;
          margin: auto;
          justify-content: center;
          align-items: center;

          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 0fr);
            gap: 10px;

            .image-box {
              background: var(--border-placeholder, rgba(204, 204, 204, 1));
              opacity: 1;
              border-radius: 10px;
            }

            .image-box-large-left {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-large-right {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-small-left {
              height: 20.5vw;
              width: 20.5vw;
            }

            .image-box-small-right {
              height: 20.5vw;
              width: 20.5vw;
              margin-top: 45px;
            }
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      margin: auto;
      margin-top: 160px;

      .upper-div {
        .left-box-section-3 {
          display: flex;
          flex-direction: column;
          width: 80%;
          justify-content: center;
          border-bottom: 4px solid rgba(50, 160, 239, 1);
          margin: auto;

          .left-box-heading {
            background: #e6f5ff;
            font-size: 16px;
            color: #32a0ef;
            width: min-content;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 15px;
            margin: auto;
          }

          .left-box-section-3-heading {
            color: rgb(51, 51, 51);
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            margin-top: 30px;
          }

          .left-box-section-3-sub-heading {
            color: rgb(51, 51, 51);
            font-size: 18px;
            margin: auto;
            width: 90%;
            margin-top: 10px;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }

      .lower-div {
        gap: 40px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left-div-lower {
          width: 50%;
          display: flex;
          margin: auto;
          margin-top: 20px;

          .iphone {
            img {
              width: 94%;
            }
          }

          .left-side-star {
            img {
              width: 20px;
              height: 110%;
            }
          }

          .right-side-star {
            img {
              width: 20px;
            }
          }
        }

        .right-div-lower {
          width: 70%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }

          .right-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 10px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .section-4 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;

      .section-4-inner-div {
        padding: auto;
        margin: auto;
      }

      .section-4-inner-div {
        background: white;
        padding: 0px;
        margin: 0px;
        border-radius: 0px 0px 10px 10px;
        height: auto;

        .left-div {
          width: 100%;
          height: 250px;
          background-color: rgba(204, 204, 204, 1);
        }

        .right-div {
          background: white;
          border-radius: 0px 0px 10px 10px;
          width: 90%;
          margin: auto;
          border-top: 4px solid rgb(50, 160, 239);
          padding: 0px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .sub-right-div {
          margin-top: 40px;

          .upper-div {
            .left-box-section-3 {
              width: 100%;
              margin: auto;
              line-height: 20px;

              .left-box-heading {
                background: #e6f5ff;
                font-size: 16px;
                color: #32a0ef;
                width: min-content;
                white-space: nowrap;
                padding: 5px 10px;
                border-radius: 15px;
                margin: auto;
              }

              h2 {
                color: rgb(51, 51, 51);
                font-size: 25px;
                line-height: 30px;
                font-weight: 700;
                text-align: left;
                margin-top: 0px;
                line-height: 30px;

                .company-name {
                  color: rgba(50, 160, 239, 1);
                  font-size: 25px;
                  font-weight: 700;
                }
              }

              p {
                color: rgb(51, 51, 51);
                font-size: 18px;
                width: 90%;
                margin-top: 10px;
                text-align: center;
              }
            }
          }

          .lower-div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .left-div-lower {
              width: 30%;
              display: flex;
              margin-top: 40px;
              margin-left: 80px;
            }

            .right-div-lower {
              width: 100%;
              flex-direction: column;
              margin-top: 20px;
              display: flex;
              gap: 25px;

              .left-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start; // Align tick and text at the top
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 25px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 1px;
                      font-weight: 400;
                      width: 100%;
                    }
                  }
                }
              }

              .right-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;
                margin-top: -20px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start;
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 20px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 15px;
                      width: 100%;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .section-5 {
      width: 100%;

      .upper-part {
        margin: auto;
        padding-top: 80px;

        .heading-firstpart {
          background: linear-gradient(90deg, #32a0ef 0%, #1d5c89 48.07%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 24px;
          font-weight: 800;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .middle-part {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .first-div {
          width: 100%;

          .tick-text-1 {
            margin-left: 10px;
          }

          .tick-text-2 {
            margin-left: 10px;
          }

          .tick-text-3 {
            margin-left: 10px;
          }

          .tick-text-1,
          .tick-text-2,
          .tick-text-3 {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }

        .second-div {
          width: 100%;
          margin: auto;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .third-div {
          width: 100%;

          .tick-text-8 {
            margin-left: 10px;
          }

          .tick-text-9,
          .tick-text-8,
          .tick-text-7 {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .lower-part {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .tick-text-5 {
          margin-top: 0px;
        }

        .tick-text-4,
        .tick-text-5,
        .tick-text-6 {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;
          align-items: flex-start;
          width: 85%;

          .frame-numb {
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            margin-top: 0px;
            font-weight: 800;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 100%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 20px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .section-6 {
      width: 100%;

      .upper-part {
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 40px;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }

        .star-section6 {
          img {
            height: 30px;
            margin-top: -10px;
          }
        }
      }

      .lower-part {
        margin-top: 50px;
        display: flex;
        gap: 50px;

        .carousel-wrapper {
          .section6-image-1 {
            img {
              width: 100%;
            }
          }

          .section6-image-2 {
            img {
              width: 100%;
            }
          }

          .section6-image-3 {
            img {
              width: 100%;
            }
          }

          .section6-image-4 {
            img {
              width: 100%;
            }
          }

          .section6-image-5 {
            img {
              width: 100%;
            }
          }

          .section6-image-6 {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .section-7 {
      width: 100%;
      margin-top: 50px;
      .section-7-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
      }
      .upper-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 85%;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .lower-part {
        width: 100%;
        height: auto;
        margin-bottom: 100px;
        background: var(--antrocorp-bg, rgba(234, 246, 255, 1));
        margin-top: 50px;

        .left-div-lower {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 100px;
          padding-bottom: 30px;
          margin-left: 0px;

          .iphone {
            width: 50%;

            img {
              width: 100%;
              height: 350px;
              margin: auto;
              margin-top: 20px;
              margin-left: 0px;
            }
          }

          .upper-part {
            gap: 10px;
            margin-top: 15px;
            display: flex;

            .tick-text-1,
            .tick-text-2,
            .tick-text-3 {
              display: flex;
              flex-direction: column;
              align-items: center;

              .frame-numb {
                margin: auto;

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 5px;
                width: 70%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 643px) and (max-width: 767.98px) {
  .product-home {
    width: 100%;

    .section-1 {
      height: 150px;
      width: 100%;
      .background-container-product-info {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .section-1-inner {
        .section-1-2 {
          margin-top: 100px;

          h1 {
            font-size: 35px;
          }

          .sub-heading {
            font-weight: 400;
            width: 50%;
            color: #333333;
          }
        }
      }
    }

    .section-2 {
      padding: 30px !important;

      .product-info-box {
        display: block !important;
        justify-content: space-between;
        padding: 20px;

        .left-div,
        .right-div {
          display: flex;
          margin-top: 20px;
          gap: 0px;
          flex-direction: column;
        }

        .left-div {
          .left-box {
            margin: auto;

            h2 {
              font-size: 20px;
              font-weight: 700;
              text-align: left;
            }

            p {
              color: rgb(51, 51, 51);
              font-size: 18px;
              width: 100%;
              text-align: left;
              margin-top: 2px;
            }
          }

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-tick-icon {
                background-color: rgba(50, 160, 239, 0.1);
                border-radius: 50px;
                width: 20px;
                height: 20px;
                margin-top: 10px;

                .tick-icon {
                  color: rgb(50, 160, 239);
                  margin-left: 8px;
                  margin-bottom: 10px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 20px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .right-div {
          display: flex;
          width: 100%;
          margin: auto;
          justify-content: center;
          align-items: center;

          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 0fr);
            gap: 10px;

            .image-box {
              background: var(--border-placeholder, rgba(204, 204, 204, 1));
              opacity: 1;
              border-radius: 10px;
            }

            .image-box-large-left {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-large-right {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-small-left {
              height: 20.5vw;
              width: 20.5vw;
            }

            .image-box-small-right {
              height: 20.5vw;
              width: 20.5vw;
              margin-top: 45px;
            }
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      margin: auto;
      margin-top: 215px;

      .upper-div {
        .left-box-section-3 {
          display: flex;
          flex-direction: column;
          width: 80%;
          justify-content: center;
          border-bottom: 4px solid rgba(50, 160, 239, 1);
          margin: auto;

          .left-box-heading {
            background: #e6f5ff;
            font-size: 16px;
            color: #32a0ef;
            width: min-content;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 15px;
            margin: auto;
          }

          .left-box-section-3-heading {
            color: rgb(51, 51, 51);
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            margin-top: 30px;
          }

          .left-box-section-3-sub-heading {
            color: rgb(51, 51, 51);
            font-size: 18px;
            margin: auto;
            width: 90%;
            margin-top: 10px;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }

      .lower-div {
        gap: 40px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left-div-lower {
          width: 50%;
          display: flex;
          margin: auto;
          margin-top: 20px;

          .iphone {
            img {
              width: 94%;
            }
          }

          .left-side-star {
            img {
              width: 20px;
              height: 120%;
            }
          }

          .right-side-star {
            img {
              width: 20px;
            }
          }
        }

        .right-div-lower {
          width: 70%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }

          .right-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 10px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .section-4 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;

      .section-4-inner-div {
        background: white;
        padding: 0px;
        margin: 0px;
        border-radius: 0px 0px 10px 10px;
        height: auto;

        .left-div {
          width: 100%;
          height: 250px;
          background-color: rgba(204, 204, 204, 1);
        }

        .right-div {
          background: white;
          border-radius: 0px 0px 10px 10px;
          width: 90%;
          margin: auto;
          border-top: 4px solid rgb(50, 160, 239);
          padding: 0px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .sub-right-div {
          margin-top: 40px;

          .upper-div {
            .left-box-section-3 {
              width: 100%;
              margin: auto;
              line-height: 20px;

              .left-box-heading {
                background: #e6f5ff;
                font-size: 16px;
                color: #32a0ef;
                width: min-content;
                white-space: nowrap;
                padding: 5px 10px;
                border-radius: 15px;
                margin: auto;
              }

              h2 {
                color: rgb(51, 51, 51);
                font-size: 25px;
                line-height: 30px;
                font-weight: 700;
                text-align: left;
                margin-top: 0px;
                line-height: 30px;

                .company-name {
                  color: rgba(50, 160, 239, 1);
                  font-size: 25px;
                  font-weight: 700;
                }
              }

              p {
                color: rgb(51, 51, 51);
                font-size: 18px;
                width: 90%;
                margin-top: 10px;
                text-align: center;
              }
            }
          }

          .lower-div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .left-div-lower {
              width: 30%;
              display: flex;
              margin-top: 40px;
              margin-left: 80px;
            }

            .right-div-lower {
              width: 100%;
              flex-direction: column;
              margin-top: 20px;
              display: flex;
              gap: 25px;

              .left-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start; // Align tick and text at the top
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 25px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 1px;
                      font-weight: 400;
                      width: 100%;
                    }
                  }
                }
              }

              .right-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;
                margin-top: -20px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start;
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 20px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 15px;
                      width: 100%;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .section-5 {
      width: 100%;
      .upper-part {
        margin: auto;
        padding-top: 80px;

        .heading-firstpart {
          background: linear-gradient(90deg, #32a0ef 0%, #1d5c89 48.07%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 24px;
          font-weight: 800;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .middle-part {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .first-div {
          width: 100%;

          .tick-text-1 {
            margin-left: 10px;
          }

          .tick-text-2 {
            margin-left: 10px;
          }

          .tick-text-3 {
            margin-left: 10px;
          }

          .tick-text-1,
          .tick-text-2,
          .tick-text-3 {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }

        .second-div {
          width: 100%;
          margin: auto;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .third-div {
          width: 100%;

          .tick-text-8 {
            margin-left: 10px;
          }

          .tick-text-9,
          .tick-text-8,
          .tick-text-7 {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .lower-part {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .tick-text-5 {
          margin-top: 0px;
        }

        .tick-text-4,
        .tick-text-5,
        .tick-text-6 {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;
          align-items: flex-start;
          width: 88%;

          .frame-numb {
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            margin-top: 0px;
            font-weight: 800;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 100%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 20px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .section-6 {
      width: 100%;

      .upper-part {
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 40px;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }

        .star-section6 {
          img {
            height: 30px;
            margin-top: -10px;
          }
        }
      }

      .lower-part {
        margin-top: 50px;
        display: flex;
        gap: 50px;

        .carousel-wrapper {
          .section6-image-1 {
            img {
              width: 100%;
            }
          }

          .section6-image-2 {
            img {
              width: 100%;
            }
          }

          .section6-image-3 {
            img {
              width: 100%;
            }
          }

          .section6-image-4 {
            img {
              width: 100%;
            }
          }

          .section6-image-5 {
            img {
              width: 100%;
            }
          }

          .section6-image-6 {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .section-7 {
      width: 100%;
      margin-top: 50px;
      .section-7-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
      }
      .upper-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 85%;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 24px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .lower-part {
        width: 100%;
        height: auto;
        margin-bottom: 100px;
        background: var(--antrocorp-bg, rgba(234, 246, 255, 1));
        margin-top: 50px;

        .left-div-lower {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 100px;
          padding-bottom: 30px;
          margin-left: 0px;

          .iphone {
            width: 40%;

            img {
              width: 100%;
              margin: auto;
              margin-top: 20px;
              margin-left: 0px;
            }
          }

          .upper-part {
            gap: 10px;
            margin-top: 15px;
            display: flex;

            .tick-text-1,
            .tick-text-2,
            .tick-text-3 {
              display: flex;
              flex-direction: column;
              align-items: center;

              .frame-numb {
                margin: auto;

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 5px;
                width: 70%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .product-home {
    width: 100%;

    .section-1 {
      height: 150px;
      width: 100%;
      .background-container-product-info {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .section-1-inner {
        top: 150px;

        .section-1-2 {
          margin-top: 100px;

          h1 {
            font-size: 35px;
          }

          .sub-heading {
            font-weight: 400;
            width: 50%;
            font-size: 25px;
            color: #333333;
          }

          .rating-outer {
            .rating-inner {
              margin-right: 10px;

              img {
                width: 13.3vw;
              }
            }

            .rating-text {
              font-size: 17px;
              font-weight: 700;
              color: #333333;
            }
          }
        }
      }
    }

    .section-2 {
      padding: 30px !important;

      .product-info-box {
        display: block !important;
        justify-content: space-between;
        padding: 20px;

        .left-div,
        .right-div {
          display: flex;
          margin-top: 20px;
          gap: 0px;
          flex-direction: column;
        }

        .left-div {
          .left-box {
            margin: auto;

            h2 {
              font-size: 36px;
              font-weight: 700;
              text-align: left;
            }

            p {
              color: rgb(51, 51, 51);
              font-size: 18px;
              width: 100%;
              text-align: left;
              margin-top: 2px;
            }
          }

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-tick-icon {
                background-color: rgba(50, 160, 239, 0.1);
                border-radius: 50px;
                width: 20px;
                height: 20px;
                margin-top: 10px;

                .tick-icon {
                  color: rgb(50, 160, 239);
                  margin-left: 8px;
                  margin-bottom: 10px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 20px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .right-div {
          display: flex;
          width: 100%;
          margin: auto;
          justify-content: center;
          align-items: center;

          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 0fr);
            gap: 10px;

            .image-box {
              background: var(--border-placeholder, rgba(204, 204, 204, 1));
              opacity: 1;
              border-radius: 10px;
            }

            .image-box-large-left {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-large-right {
              width: 20.5vw;
              height: 25.6vw;
            }

            .image-box-small-left {
              height: 20.5vw;
              width: 20.5vw;
            }

            .image-box-small-right {
              height: 20.5vw;
              width: 20.5vw;
              margin-top: 45px;
            }
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      margin: auto;
      margin-top: 350px;

      .upper-div {
        .left-box-section-3 {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          border-bottom: 4px solid rgba(50, 160, 239, 1);
          margin: auto;

          .left-box-heading {
            background: #e6f5ff;
            font-size: 16px;
            color: #32a0ef;
            width: min-content;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 15px;
            margin: auto;
          }

          .left-box-section-3-heading {
            color: rgb(51, 51, 51);
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            margin-top: 30px;
          }

          .left-box-section-3-sub-heading {
            color: rgb(51, 51, 51);
            font-size: 18px;
            margin: auto;
            width: 90%;
            margin-top: 10px;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }

      .lower-div {
        gap: 40px;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .left-div-lower {
          width: 100%;
          display: flex;
          margin-top: 20px;

          .iphone {
            img {
              width: 94%;
            }
          }

          .left-side-star {
            margin-top: -120px;

            img {
              width: 20px;
              height: 110%;
            }
          }

          .right-side-star {
            img {
              width: 20px;
            }
          }
        }

        .right-div-lower {
          width: 90%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }

          .right-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 10px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 16px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .section-4 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;

      .section-4-inner-div {
        background: white;
        padding: 0px;
        margin: 0px;
        border-radius: 0px 0px 10px 10px;
        height: auto;

        .left-div {
          width: 100%;
          height: 250px;
          background-color: rgba(204, 204, 204, 1);
        }

        .right-div {
          background: white;
          border-radius: 0px 0px 10px 10px;
          width: 90%;
          margin: auto;
          border-top: 4px solid rgb(50, 160, 239);
          padding: 0px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .sub-right-div {
          margin-top: 40px;

          .upper-div {
            .left-box-section-3 {
              width: 100%;
              margin: auto;
              line-height: 20px;
              justify-content: center;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;

              .left-box-heading {
                background: #e6f5ff;
                font-size: 16px;
                color: #32a0ef;
                width: min-content;
                white-space: nowrap;
                padding: 5px 10px;
                border-radius: 15px;
                margin: auto;
              }

              h2 {
                color: rgb(51, 51, 51);
                font-size: 36px;
                line-height: 30px;
                font-weight: 700;
                text-align: left;
                margin-top: 0px;
                line-height: 30px;

                .company-name {
                  color: rgba(50, 160, 239, 1);
                  font-size: 36px;
                  font-weight: 700;
                }
              }

              p {
                color: rgb(51, 51, 51);
                font-size: 18px;
                width: 90%;
                margin-top: 10px;
                text-align: center;
              }
            }
          }

          .lower-div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .left-div-lower {
              width: 30%;
              display: flex;
              margin-top: 40px;
              margin-left: 80px;
            }

            .right-div-lower {
              width: 100%;
              flex-direction: column;
              margin-top: 20px;
              display: flex;
              gap: 25px;

              .left-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start; // Align tick and text at the top
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 25px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 1px;
                      font-weight: 400;
                      width: 100%;
                    }
                  }
                }
              }

              .right-sub-box {
                display: flex;
                flex-direction: column;
                gap: 0px;
                margin-top: -20px;

                .tick-text {
                  display: flex;
                  margin-bottom: 30px;
                  align-items: flex-start;
                  gap: 5px;

                  .frame-numb {
                    width: 3.5px;
                    height: 13px;
                    margin-top: 6px;
                    background-color: #32a0ef;
                  }

                  .main-sub-heading-third-div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 5px;
                    width: 100%;

                    .sub-heading-third-div {
                      font-weight: bold;
                      font-size: 20px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .sub-content-third-div {
                      color: rgba(51, 51, 51, 1);
                      font-size: 15px;
                      width: 100%;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .section-5 {
      width: 100%;
      .upper-part {
        margin: auto;
        padding-top: 80px;

        .heading-firstpart {
          background: linear-gradient(90deg, #32a0ef 0%, #1d5c89 48.07%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 36px;
          font-weight: 800;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 34px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .middle-part {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .first-div {
          width: 100%;

          .tick-text-1 {
            margin-left: 10px;
          }

          .tick-text-2 {
            margin-left: 10px;
          }

          .tick-text-3 {
            margin-left: 10px;
          }

          .tick-text-1,
          .tick-text-2,
          .tick-text-3 {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }

        .second-div {
          width: 100%;
          margin: auto;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .third-div {
          width: 100%;

          .tick-text-8 {
            margin-left: 10px;
          }

          .tick-text-9,
          .tick-text-8,
          .tick-text-7 {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            align-items: flex-start;

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              margin-top: 0px;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .lower-part {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: auto;

        .tick-text-5 {
          margin-top: 0px;
        }

        .tick-text-4,
        .tick-text-5,
        .tick-text-6 {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;
          align-items: flex-start;
          width: 91%;

          .frame-numb {
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            margin-top: 0px;
            font-weight: 800;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 100%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 20px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .section-6 {
      width: 100%;

      .upper-part {
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 40px;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 36px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 34px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }

        .star-section6 {
          img {
            height: 30px;
            margin-top: -10px;
          }
        }
      }

      .lower-part {
        margin-top: 50px;
        display: flex;
        gap: 50px;

        .carousel-wrapper {
          .section6-image-1 {
            img {
              width: 100%;
            }
          }

          .section6-image-2 {
            img {
              width: 100%;
            }
          }

          .section6-image-3 {
            img {
              width: 100%;
            }
          }

          .section6-image-4 {
            img {
              width: 100%;
            }
          }

          .section6-image-5 {
            img {
              width: 100%;
            }
          }

          .section6-image-6 {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .section-7 {
      width: 100%;
      margin-top: 50px;
      .section-7-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
      }
      .upper-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 36px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 34px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .lower-part {
        width: 100%;
        height: auto;
        margin-bottom: 100px;
        background: var(--antrocorp-bg, rgba(234, 246, 255, 1));
        margin-top: 50px;

        .left-div-lower {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 100px;
          padding-bottom: 30px;
          margin-left: 0px;

          .iphone {
            width: 33%;

            img {
              width: 100%;
              margin: auto;
              margin-top: 20px;
              margin-left: 0px;
            }
          }

          .upper-part {
            gap: 10px;
            margin-top: 15px;
            display: flex;

            .tick-text-1,
            .tick-text-2,
            .tick-text-3 {
              display: flex;
              flex-direction: column;
              align-items: center;

              .frame-numb {
                margin: auto;

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 5px;
                width: 70%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 20px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .product-home {
    .section-1 {
      width: 100%;
      position: relative;
      .background-container-product-info {
        display: flex;
        z-index: -1;

        img {
          width: 100%;
        }
      }

      .section-1-inner {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        .section-1-2 {
          margin-left: 80px;

          .cta-button {
            border: solid 1px #32a0ef;
            border-radius: 50px;
            padding: 5px 10px;
            background: white;
            color: rgb(87, 87, 87);
            display: flex;
            align-items: center;
          }

          h1 {
            margin: 20px auto;
            font-size: 60px;
            color: rgba(50, 160, 239, 1);
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
          }

          .sub-heading {
            margin-bottom: 20px;
            color: #333333;
          }

          .rating-outer {
            display: flex;
            align-items: center;

            .rating-inner {
              margin-right: 10px;

              img {
                width: 6.5vw;
              }
            }

            .rating-text {
              font-size: 14px;
              font-weight: 700;
              color: #333333;
            }

            .rating {
              font-size: 13px;

              .star {
                color: #ffb636;
              }

              .rating-text-inner {
                color: grey;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .section-2 {
      padding: 80px;

      .product-info-box {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .left-div,
        .right-div {
          flex: 45%;
          padding: 10px;
        }

        .left-div {
          .left-box {
            margin-bottom: 20px;

            h2 {
              color: rgb(51, 51, 51);
              font-size: 30px;
              font-weight: 700;
              text-align: left;
            }

            p {
              color: rgb(51, 51, 51);
              font-size: 17px;
              width: 100%;
              text-align: left;
            }
          }

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-tick-icon {
                background-color: rgba(50, 160, 239, 0.1);
                border-radius: 50px;
                width: 20px;
                height: 20px;
                margin-top: 10px;

                .tick-icon {
                  color: rgb(50, 160, 239);
                  margin-left: 8px;
                  margin-bottom: 10px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 22px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .right-div {
          margin-left: 10%;

          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 0fr);
            gap: 10px;

            .image-box {
              background: var(--border-placeholder, rgba(204, 204, 204, 1));
              opacity: 1;
              border-radius: 10px;
            }

            .image-box-large-left {
              width: 11.5vw;
              height: 14.6vw;
              margin-bottom: 0;
              margin-top: auto;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            .image-box-large-right {
              width: 11.5vw;
              height: 14.6vw;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            .image-box-small-left {
              width: 11.5vw;
              height: 11.5vw;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            .image-box-small-right {
              width: 11.5vw;
              height: 11.5vw;
              margin-top: 45px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }

    .section-3 {
      margin: 80px 80px 10px 80px;
      .upper-div {
        .left-box-section-3 {
          display: flex;
          padding-bottom: 30px;
          border-bottom: 4px solid rgba(50, 160, 239, 1);
          margin: auto;

          .left-box-heading {
            background: #e6f5ff;
            font-size: 16px;
            color: #32a0ef;
            width: min-content;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 15px;
            margin: auto;
          }

          .left-box-section-3-heading {
            color: rgb(51, 51, 51);
            font-size: 35px;
            font-weight: 700;
            text-align: left;
            margin-top: 22px;
          }

          .left-box-section-3-sub-heading {
            color: rgb(51, 51, 51);
            font-size: 17px;
            width: 100%;
            margin-top: 40px;
            text-align: left;
          }
        }
      }

      .lower-div {
        display: flex;
        margin: 40px auto;

        .left-div-lower {
          width: 33%;
          display: flex;
          .iphone {
            img {
              width: 94%;
              margin-top: 30px;
            }
          }

          .left-side-star {
            img {
              width: 20px;
              height: 110%;
            }
          }

          .right-side-star {
            img {
              width: 20px;
            }
          }
        }

        .right-div-lower {
          width: 90%;
          margin-top: 35px;
          display: flex;

          .left-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 22px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }

          .right-sub-box {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 40px;

            .tick-text {
              display: flex;
              margin-bottom: 30px;
              align-items: flex-start; // Align tick and text at the top
              gap: 15px;

              .frame-numb {
                background: linear-gradient(
                  180deg,
                  #32a0ef 0%,
                  #ffffff 143.08%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-family: monospace;
                margin-top: -25px;
                font-weight: 800;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 85%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 22px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .section-4 {
      display: flex;
      margin: 0px 0px 0px 0px;

      .section-4-inner-div {
        margin: 80px;
        background: white;
        border-radius: 0px 0px 10px 10px;
        width: 100%;
        padding: 0px 45px;
        height: 100%;

        .sub-right-div {
          margin-top: 40px;

          .upper-div {
            .left-box-section-3 {
              width: 70%;
              margin: auto;
              line-height: 20px;
              text-align: center;

              .left-box-heading {
                background: #e6f5ff;
                font-size: 16px;
                color: #32a0ef;
                width: min-content;
                white-space: nowrap;
                padding: 5px 10px;
                border-radius: 15px;
                margin: auto;
              }

              h2 {
                color: rgb(51, 51, 51);
                font-size: 30px;
                font-weight: 700;
                margin-top: 30px;

                .company-name {
                  color: rgba(50, 160, 239, 1);
                  font-size: 30px;
                  font-weight: 700;
                }
              }

              p {
                color: rgb(51, 51, 51);
                font-size: 17px;
                width: 100%;
                margin-top: 30px;
              }
            }
          }

          .lower-div {
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            .tick-text {
              display: inline-grid;
              margin-bottom: 30px;
              align-items: flex-start;
              gap: 5px;
              padding: 20px;
              border: 1px solid #f1f1f1;
              box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
              border-radius: 20px;

              .frame-numb {
                color: #32a0ef;
                font-size: 30px;
                margin-top: 6px;
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 5px;
                width: 100%;
                text-align: center;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 18px;
                  color: rgba(51, 51, 51, 1);
                  padding: 10px;
                  border-bottom: 2px solid #32a0ef;
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 400;
                  width: 100%;
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }

    .section-5 {
      .upper-part {
        margin: auto;
        padding-top: 80px;

        .heading-firstpart {
          background: linear-gradient(90deg, #32a0ef 0%, #1d5c89 48.07%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 36px;
          font-weight: 800;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 34px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .middle-part {
        margin: 50px 100px 0px 80px;
        display: flex;
        justify-content: space-between;

        .first-div {
          width: 23%;

          .tick-text-1,
          .tick-text-2,
          .tick-text-3 {
            background: rgb(255 255 255 / 34%);
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            align-items: flex-start;
            padding: 20px;
            border: 1px solid #32a0ef;
            border-radius: 15px;
            backdrop-filter: blur(2px);

            .frame-numb {
              width: 25px;
              margin: 0px 0px 20px 0px;
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 22px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .second-div {
          width: 50%;
          margin-top: -23px;

          img {
            width: 100%;
            margin-top: 300px;
          }
        }

        .third-div {
          width: 23%;

          .tick-text-8 {
          }

          .tick-text-9,
          .tick-text-8,
          .tick-text-7 {
            background: rgb(255 255 255 / 34%);
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            padding: 20px;
            border: 1px solid #32a0ef;
            align-items: flex-start;
            border-radius: 15px;
            backdrop-filter: blur(2px);

            .frame-numb {
              background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
              margin: 0px 0px 20px 0px;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 40px;
              font-family: monospace;
              font-weight: 800;
            }

            .main-sub-heading-third-div {
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 5px;
              width: 100%;
              margin-top: -10px;

              .sub-heading-third-div {
                font-weight: bold;
                font-size: 22px;
                color: rgba(51, 51, 51, 1);
              }

              .sub-content-third-div {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .lower-part {
        display: flex;
        justify-content: space-between;
        margin: 0px 80px;

        .tick-text-4,
        .tick-text-5,
        .tick-text-6 {
          background: rgb(255 255 255 / 34%);
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;
          align-items: center;
          width: 21%;
          padding: 20px;
          border: 1px solid #32a0ef;
          align-items: flex-start;
          border-radius: 15px;
          backdrop-filter: blur(2px);

          .frame-numb {
            background: linear-gradient(180deg, #32a0ef 0%, #ffffff 143.08%);
            margin: 0px 0px 20px 0px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-family: monospace;
            font-weight: 800;
            margin-right: 206px;
          }

          .main-sub-heading-third-div {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 5px;
            width: 71%;
            margin-top: -10px;

            .sub-heading-third-div {
              font-weight: bold;
              font-size: 22px;
              color: rgba(51, 51, 51, 1);
            }

            .sub-content-third-div {
              color: rgba(51, 51, 51, 1);
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .section-6 {
      .upper-part {
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 40px;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 36px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 34px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }

        .star-section6 {
          img {
            height: 30px;
            margin-top: -10px;
          }
        }
      }

      .lower-part {
        margin-top: 50px;
        display: flex;
        gap: 50px;

        .carousel-wrapper {
          .section6-image-1 {
            img {
              width: 18%;
            }
          }

          .section6-image-2 {
            img {
              width: 18%;
            }
          }

          .section6-image-3 {
            img {
              width: 18%;
            }
          }

          .section6-image-4 {
            img {
              width: 18%;
            }
          }

          .section6-image-5 {
            img {
              width: 18%;
            }
          }

          .section6-image-6 {
            img {
              width: 18%;
            }
          }
        }
      }
    }

    .section-7 {
      margin-top: 50px;
      .section-7-sub-heading {
        background: #e6f5ff;
        font-size: 16px;
        color: #32a0ef;
        width: min-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 15px;
        margin: auto;
      }
      .upper-part {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 85%;

        .heading-firstpart {
          color: rgb(50, 160, 239);
          font-size: 36px;
          font-weight: 700;
          text-align: center;
        }

        .heading-secondpart {
          font-size: 34px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
      }

      .lower-part {
        width: 100%;
        height: 425px;
        margin-bottom: 100px;
        background: var(--antrocorp-bg, rgba(234, 246, 255, 1));
        margin-top: 50px;

        .left-div-lower {
          display: flex;
          margin-top: 100px;
          margin-left: 80px;

          .iphone {
            width: 70%;

            img {
              height: 450px;
              margin-top: -46px;
            }
          }

          .upper-part {
            gap: 10px;
            margin-top: 15px;
            display: flex;

            .tick-text-1,
            .tick-text-2,
            .tick-text-3 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .frame-numb {
                margin: auto;

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .main-sub-heading-third-div {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 5px;
                width: 100%;

                .sub-heading-third-div {
                  font-weight: bold;
                  font-size: 22px;
                  color: rgba(51, 51, 51, 1);
                }

                .sub-content-third-div {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
}
